import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeVesselEntity } from "domain/entity/ChargeDetailMaintenance/ChargeVesselEntity";
import { EMPTY_CHARGE_VESSEL_SEARCH_CRITERIA } from "domain/entity/ChargeDetailMaintenance/ChargeVesselSearchCriteria";
import { ChargeDetailRepository } from "domain/repository/ChargeDetailMaintenance/ChargeDetailRepo";
import _ from "lodash";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { Dispatch, SetStateAction } from "react";
import { GroupCheckboxList, GroupRadioChangeProps } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface ChargeVesselVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChargeDetailModel>> | ((value: SetStateAction<ChargeDetailModel>) => void),
    ],
    chargeDetailRepo: ChargeDetailRepository,
}

export const ChargeVesselVM = ({ dispatch, chargeDetailRepo
}: ChargeVesselVMProps) => {
    const [chargeDetailDispatch] = dispatch;

    const onDropdownChange = (e: any, fieldName: string, obj: any, objName: string) => {
        let val = null;
        if (_.isArray(e)) {
            val = _.uniq(e?.map((item: DropdownProps) => item.value))
        } else {
            val = e?.value
        }
        obj = {
            ...obj,
            [fieldName]: val
        };
        chargeDetailDispatch(prevState => ({
            ...prevState,
            [objName]: obj
        }))
    }

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            chargeBatchUpdate: {
                ...prevState.chargeBatchUpdate,
                [fieldName.co]: inputData?.co,
                [fieldName.vsl]: inputData?.vsl,
                [fieldName.voy]: inputData?.voy,
            }
        }))
    }

    const onDateChange = (fieldName: string, e: any, obj: any, objName: string) => {
        obj = {
            ...obj,
            [fieldName]: e
        };
        chargeDetailDispatch(prevState => ({
            ...prevState,
            [objName]: obj
        }))
    }

    const onFieldChange = (valueKeys: { [fieldName: string]: any }, obj: any, objName: string) => {
        _.forEach(valueKeys, function (value, key) {
            obj = {
                ...obj,
                [key]: value
            }
        });
        chargeDetailDispatch(prevState => ({
            ...prevState,
            [objName]: obj
        }))
    }

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], fieldName: string, obj: any, ObjName: string) => {
        let selectedValue: string[] = [];
        if (e) {
            e.forEach(function (value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        obj = { ...obj, [fieldName]: selectedValue }

        chargeDetailDispatch(prevState => ({
            ...prevState,
            [ObjName]: obj,
        }));
    };

    const onRadioChange = (e: GroupRadioChangeProps, fieldName: string, obj: any, ObjName: string) => {
        if (e.checked) {
            obj = {
                ...obj,
                [fieldName]: e.key
            }
            chargeDetailDispatch(prevState => ({
                ...prevState,
                [ObjName]: obj,
            }));
        }
    }

    const updateSelectedVslVoys = (selectedRows: ChargeDetailEntity[]) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            selectedChargeVessels: selectedRows
        }));
    }

    // const searchChargeDetailDynStatus = async (searchCriteria: ChargeVesselSearchCriteria) => {
    //     chargeDetailDispatch(prevState => ({
    //         ...prevState,
    //         chargeVessels: [],
    //         selectedChargeVessels: []
    //     }));
    //     await chargeDetailRepo.searchChargeDetailDynStatus(searchCriteria).then(data => {
    //         chargeDetailDispatch(prevState => ({
    //             ...prevState,
    //             chargeVessels: data,
    //             selectedChargeVessels: []
    //         }));
    //     }).catch(error => {
    //         chargeDetailDispatch(prevState => ({
    //             ...prevState,
    //             chargeVessels: [],
    //             selectedChargeVessels: []
    //         }))
    //     })
    // }

    const onCancelButtonClicked = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: false,
                isShowUpdateStatus: false,
            }
        })
    }

    const onResetButtonClicked = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                chargeVesselSearchCriteria: EMPTY_CHARGE_VESSEL_SEARCH_CRITERIA
            }
        })
    }

    const addDummyVesselInfo = (vesselInfo: ChargeDetailEntity) => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                chargeBatchUpdate: {
                    ...prevState.chargeBatchUpdate,
                    otherLegCharge: vesselInfo,
                }
            }
        })
    }

    const onApplyButtonClicked = async (changeActionOption: number, updateStatusType: string,
        newSubChargeType: string, receiveDatetime: Date | null,
        selectChargeDetails: ChargeDetailEntity[], newStatus: string,
        consortiumCode: string | null, vesselCode: string | null, voyageCode: string | null,
        vesselInfo?: ChargeDetailEntity | null
    ) => {
        const chargeVesselEntity: ChargeVesselEntity = ({
            changeActionIndex: changeActionOption,
            subChargeType: newSubChargeType,
            receiveDatetime: receiveDatetime,
            newStatus: newStatus,
            vesselInfo: vesselInfo,
            selectChargeDetails: selectChargeDetails,
            updateStatusType: updateStatusType,
            consortiumCode: consortiumCode,
            vesselCode: vesselCode,
            voyageCode: voyageCode,
        });
        await chargeDetailRepo.updateCntrStatus(chargeVesselEntity).then(data => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "updateCntrStatusSuccessful": "Update Status Failure." }
                }
            })
        }).catch(error => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "updateCntrStatusFail": "Update Status Failure." }
                }
            })
        })
    }

    return {
        onDropdownChange: onDropdownChange,
        onCoVslVoyChange: onCoVslVoyChange,
        onDateChange: onDateChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
        onRadioChange: onRadioChange,
        updateSelectedVslVoys: updateSelectedVslVoys,
        // searchChargeDetailDynStatus: searchChargeDetailDynStatus,
        onCancelButtonClicked: onCancelButtonClicked,
        onResetButtonClicked: onResetButtonClicked,
        onApplyButtonClicked: onApplyButtonClicked,
        addDummyVesselInfo: addDummyVesselInfo,
        onFieldChange: onFieldChange,
    }
}