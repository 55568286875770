import { SelectionChangedEvent } from "ag-grid-community";
import { ChargeDetailStatePolicy } from "constants/charge/ChargeDetailStatePolicy";
import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeDetailSearchCriteria, EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSearchCriteria";
import { ChargeSummaryMap } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSummaryEntity";
import _ from "lodash";
import { INITIAL_CHARGE_DETAIL_COL_DEF, transferRowData } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailColumnDefinition";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { CheckButton, Loader } from "veronica-ui-component/dist/component/core";

const ChargeDetailTablePanel = () => {
    const [chargeDetailState] = useChargeDetailTracked();
    const chargeDetailVM = useChargeDetailVM();
    const tableId = 'charge-detail-table';
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [groupButtonChecked, setGroupButtonChecked] = useState<{ [key: string]: boolean }>({});
    const [groupButtons, setGroupButtons] = useState<void | ChargeSummaryMap | undefined>();
    const [badgeComponents, setBadgeComponents] = useState<ReactNode>();
    const { isShowBatchUpdatePanel, isShowCntrInfoPanel, isShowUpdateVesselVoyageMasterSearchPanel,
        isShowUpdateVesselVoyageMasterTablePanel, isShowAssignSeqNoPanel, isShowUpdateStatus } = chargeDetailState;

    const isDisable = useCallback(() => {
        if (isShowBatchUpdatePanel || isShowCntrInfoPanel || isShowUpdateVesselVoyageMasterSearchPanel ||
            isShowUpdateVesselVoyageMasterTablePanel || isShowAssignSeqNoPanel || isShowUpdateStatus) {
            return true;
        } else {
            return false;
        }
    }, [isShowBatchUpdatePanel, isShowCntrInfoPanel, isShowUpdateVesselVoyageMasterSearchPanel, isShowUpdateVesselVoyageMasterTablePanel, isShowAssignSeqNoPanel, isShowUpdateStatus]);

    const handleRowDoubleClick = useCallback((chargeDetail: ChargeDetailEntity) => {
        chargeDetailVM.handleRowDoubleClick(chargeDetail);
    }, [chargeDetailVM]);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        chargeDetailVM.updateSelectedCharges(selectedRows);
    }, [chargeDetailVM]);

    // const onBackAction = useCallback(async() => {
    //     const vesselData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY)?
    //         JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY)??""):
    //         null;
    //     const inboundData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY)?
    //         JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY)??""):
    //         null;
    //     const changeKeyData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA)?
    //         JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA)??""):
    //         null;
    //     const deliveryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY)?
    //         JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY)??""):
    //         null;
    //     //open charge header screen        
    //     if (vesselData) {
    //         customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT, 
    //             E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY, 
    //             { userName: anaInfoState.userName,
    //             data: vesselData,
    //             });
    //     } else if (inboundData) {
    //         customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT, 
    //             E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY, 
    //             { userName: anaInfoState.userName,
    //             data: inboundData,
    //             });
    //     } else if (changeKeyData) {
    //         customDispatchEvent(E_Type_Of_Event.CHANGE_KEY_DATA_REDIRECT_EVENT, 
    //             E_Custom_Dispatch_Event.REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL, 
    //             { userName: anaInfoState.userName,
    //             data: changeKeyData,
    //             });
    //     } else if (deliveryData) {
    //         customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT, 
    //             E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY, 
    //             { userName: anaInfoState.userName,
    //                 data: deliveryData,
    //             });
    //     }

    //     //remove the redirect cache
    //     localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY)
    //     localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA)
    //     localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY);
    //     localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY);
    // }, [anaInfoState.userName]);

    const handleGroupButtonClick = useCallback(async (buttonId: string) => {
        if (buttonId && chargeDetailState.currentChargeHeader?.id) {
            setIsLoading(true);

            const searchCriteria: ChargeDetailSearchCriteria = {
                ...EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA,
                states: Object.values(ChargeDetailStatePolicy)?.filter(dtl => !["DI", "I", "T", "MT", "X", "Z"].includes(dtl)),
                chargeTypes: [buttonId],
                chargeHdrId: chargeDetailState.currentChargeHeader?.id,
            }

            if (!groupButtonChecked[buttonId]) {
                chargeDetailVM.getChargeDetails(searchCriteria, false).then(data => {
                    setIsLoading(false);
                    setGroupButtonChecked({ [buttonId]: true });
                });
            } else {
                chargeDetailVM.getChargeDetails(chargeDetailState.searchCriteria, true).then(data => {
                    setIsLoading(false);
                    setGroupButtonChecked({ [buttonId]: false });
                });
            }

        }
    }, [chargeDetailState.currentChargeHeader?.id, chargeDetailState.searchCriteria, chargeDetailVM, groupButtonChecked]);

    useEffect(() => {
        if (!chargeDetailState.refreshBedge) return;

        chargeDetailVM.getChargeDetailOutstanding(chargeDetailState.currentChargeHeader).then(data => {
            if (data && !_.isNaN(data)) {
                setGroupButtons(data);
            }
        });
    }, [chargeDetailState.currentChargeHeader, chargeDetailState.refreshBedge, chargeDetailVM]);

    useEffect(() => {
        if (!chargeDetailState.isRefreshButton) return;
        setGroupButtonChecked({});
    }, [chargeDetailState.isRefreshButton]);

    useEffect(() => {
        if (!!!groupButtons) return;

        const components = <>
            {_.map(_.toPairs(groupButtons), ([key, value]) => {
                let totalCount = 0;
                _.map(_.toPairs(value), ([key, value]) => {
                    totalCount = totalCount + (value ? Number(value) : 0);
                })
                return (
                    <CheckButton
                        checked={groupButtonChecked[key] ?? false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleGroupButtonClick(e.target.id) }}
                        disabled={(totalCount > 0 ? false : true) || isDisable()}
                        showBadge={true}
                        badgeValue={totalCount && totalCount > 999 ? "999+" : totalCount.toString()}
                        size="medium"
                        inputId={key}
                        label={key}
                    />
                )
            }
            )}
        </>
        setBadgeComponents(components);
    }, [groupButtonChecked, groupButtons, handleGroupButtonClick, isDisable])

    const memoGroupTable = useMemo(() => {
        return (
            <NbisTable
                id={tableId}
                isNewColumnSetting={true}
                columns={INITIAL_CHARGE_DETAIL_COL_DEF}
                headerLabel={
                    // showBackButtonWithTitle ?
                    // <>
                    // <HeaderWithBackButton callback={onBackAction}>
                    //     {CHARGEDETAIL_CONSTANT.CHARGEPROCESSING_TITLE}                    
                    // </HeaderWithBackButton>
                    // <HeaderTitle initialComponentsFunction={initialGroupButton} param={chargeDetailState.currentChargeHeader}>
                    // </HeaderTitle>
                    // </>
                    // :
                    // <HeaderTitle initialComponentsFunction={initialGroupButton} param={chargeDetailState.currentChargeHeader}>
                    // </HeaderTitle>
                    <HeaderTitle children={badgeComponents} />
                    // CHARGEDETAIL_CONSTANT.CHARGEPROCESSING_TITLE
                }
                onRowDoubleClick={(e: any, chargeDetail: ChargeDetailEntity) => handleRowDoubleClick(chargeDetail)}
                data={transferRowData(chargeDetailState.chargeDetails ?? [])} //chargeDetailState.chargeDetails
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                showUploadIcon={true}
                isScrollHighlighted={true}
                selectionMode={false}
                onSelectionChanged={handleSelectionChange}
                rowSelection={"multiple"}
                gridHeight="customHeight"
                customHeight="calc(78vh)"
                groupDisplayType={"groupRows"}
                rowGroupPanelShow={"always"} />
        );
    }, [badgeComponents, chargeDetailState.chargeDetails, handleSelectionChange, handleRowDoubleClick]);

    // const memoChargeHeaderPanel = useMemo(() => {
    //     return <ChargeDetailHeaderPanel/>
    // }, [])

    return <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
        {isLoading && <Loader size="Medium" Indicator="Spinner" />}
        <TableWrapper>
            {memoGroupTable}
        </TableWrapper>
    </div>
    // <SliderPanel
    //         isOpen={true}
    //         draggable={true}
    //         leftSectionWidth={"20%"}
    //         rightSectionWidth={"80%"}
    //         leftChildren={memoChargeHeaderPanel}
    //         rightChildren={<TableWrapper>
    //             {memoGroupTable}
    //         </TableWrapper>} 
    //     />

}

export default memo(ChargeDetailTablePanel);