import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeDetailSearchCriteria } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSearchCriteria";
import { ChargeSummaryMap } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSummaryEntity";
import { ChargeVesselEntity } from "domain/entity/ChargeDetailMaintenance/ChargeVesselEntity";
import { ValidateMasterBundleRequest } from "domain/entity/ChargeDetailMaintenance/ValidateMasterBundleRequest";
import { VesselVoyageMasterEntity } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterEntity";
import { ChargeHeaderEntity } from "domain/entity/ChargeHeader/ChargeHeaderEntity";
import { DeliveryChargeSummaryEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryEntity";
import { ChargeBatchUpdateEntity } from "presentation/constant/ChargeDetailMaintenance/ChargeBatchUpdateEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ChargeDetailRepository } from "./ChargeDetailRepo";

export const ChargeDetailRepoImpl = (): ChargeDetailRepository => {
    const getChargeDetails = async (searchCriteria: ChargeDetailSearchCriteria): Promise<ChargeDetailEntity[]> => {
        const url = "/v1/chargeDetailSearch"
        return await axiosPostData(chargeAxiosInstance, `${url}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const batchUpdate = async (chargeList: ChargeDetailEntity[]): Promise<boolean> => {
        const newUrl = "/v1/chargeDetailBatchUpdate"
        return await axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeList).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Batch Edit Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const vslVoybatchUpdate = async (vesselVoyageMasterData: VesselVoyageMasterEntity, chargeListData: ChargeDetailEntity[]): Promise<boolean> => {

        const newUrl = "/v1/chargeDetailVesselVoyage"

        let chargeListWithVoyage =
        {
            vesselVoyageMaster: vesselVoyageMasterData,
            chargeList: chargeListData,
        }


        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeListWithVoyage).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Change Vsl/Voy Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const apply = async (chargeList: ChargeDetailEntity[]): Promise<boolean> => {
        const newUrl = "/v1/chargeProcess/apply"

        return await axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeList).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Upate Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const confirm = async (chargeList: ChargeDetailEntity[]): Promise<boolean> => {
        const newUrl = "/v1/chargeProcess/confirm"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeList).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Confirm Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const applyAll = async (chargeHeader: ChargeHeaderEntity): Promise<boolean> => {
        const newUrl = "/v1/chargeProcess/applyAll"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeHeader).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Upate All Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const confirmAll = async (chargeHeader: ChargeHeaderEntity): Promise<boolean> => {
        const newUrl = "/v1/chargeProcess/confirmAll"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeHeader).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Confirm All Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const unConfirmAll = async (chargeHeader: ChargeHeaderEntity): Promise<boolean> => {
        const newUrl = "/v1/chargeProcess/unconfirmall"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeHeader).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Unconfirm All Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const adjustment = async (chargeList: ChargeDetailEntity[]): Promise<boolean> => {
        const newUrl = "/v1/adjustment"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeList).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Adjustment Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const deleteCharge = async (chargeList: ChargeDetailEntity[]): Promise<boolean> => {
        const newUrl = "/v1/deleteCharge"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeList).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Delete Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const unConfirm = async (chargeList: ChargeDetailEntity[]): Promise<boolean> => {
        const newUrl = "/v1/chargeProcess/unconfirm"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeList).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Unconfirm Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const getSummary = (chargeHeader: ChargeHeaderEntity): Promise<ChargeSummaryMap> => {
        const newUrl = "/v1/chargeDetailGetSummary";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeHeader).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Show Charge Detail Summary Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch((err) => {
            return "Error:" + err.Message;
        })
    }

    const assignCommonAccount = (ids: string[]): Promise<boolean> => {
        const newUrl = "/v1/assignCommonAccount";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, ids).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Assign Common Account Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch((err) => {
            return "Error:" + err.Message;
        })
    }
    const redirectCommonAccount = (ids: string[]): Promise<boolean> => {
        const newUrl = "/v1/redirectCommonAccount";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, ids).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Redirect Common Account Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch((err) => {
            return "Error:" + err.Message;
        })
    }
    const reverseCommonAccount = (commonAcSeqs: Object[]): Promise<boolean> => {
        const newUrl = "/v1/reverseCommonAccount";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, commonAcSeqs).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Reverse Common Account Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch((err) => {
            return "Error:" + err.Message;
        })
    }

    const initBulkEditMenuItem = (chargeList: ChargeDetailEntity[]): Promise<ChargeBatchUpdateEntity> => {
        const newUrl = "/v1/initBulkEditMenuItem";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeList).then((res) => {
            const data = res.data;
            return data;
        }).catch((error) => {
            return [];
        })
    }

    // const searchChargeDetailDynStatus = (searchCriteria:ChargeVesselSearchCriteria) : Promise<ChargeDetailEntity[]> => {
    //     const newUrl = "/v1/SearchChargeDetailDynStatus";
    //     return axiosPostData(chargeAxiosInstance, `${newUrl}`, searchCriteria).then((res) => {
    //         const data = res.data;
    //         return data;
    //     }).catch((error) => {
    //         return [];
    //     })
    // }

    const updateCntrStatus = (chargeVesselEntity: ChargeVesselEntity): Promise<any> => {
        const newUrl = "/v1/updateCntrStatus";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeVesselEntity).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Update Cntr Status Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch((err) => {
            return "Error:" + err.Message;
        })
    }

    const getChargeDetailsForDeliverySummary = async (deliveryChargeSummary: DeliveryChargeSummaryEntity): Promise<any> => {
        const url = "/v1/chargeDetailSearchForDeliverySummary"
        return await axiosPostData(chargeAxiosInstance, `${url}`, deliveryChargeSummary).then(res => {
            if (res.code === "200") {
                const data = res.data;
                return data;
            } else {
                return res.msg
            }
        }).catch(err => {
            return [];
        });
    }

    const getOutstanding = (chargeHeader: ChargeHeaderEntity): Promise<ChargeSummaryMap> => {
        const newUrl = "/v1/chargeDetailGetOutstanding";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, chargeHeader).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Show Charge Detail Outstanding Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch((err) => {
            return "Error:" + err.Message;
        })
    }

    const getConVslVoy = (cntrNos: string): Promise<ChargeDetailEntity[]> => {
        const newUrl = "/v1/searchConVslVoy";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, cntrNos.replace(/^,+|,+$/g, '').split(',')).then((res) => {
            const data = res.data;
            return data;
        }).catch((error) => {
            return [];
        })
    }

    const validateMasterBundle = (validateMasterBundleRequest: ValidateMasterBundleRequest): Promise<boolean> => {
        const newUrl = "/v1/validateMasterBundle";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, validateMasterBundleRequest).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Validate bundle master no fail"
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch((err) => {
            return "Error:" + err.Message;
        })
    }

    const searchMasterBundleVisit = (validateMasterBundleRequest: ValidateMasterBundleRequest): Promise<string[]> => {
        const newUrl = "/v1/searchMasterBundleVisit";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, validateMasterBundleRequest).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Get visit of bundle master no fail"
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch((err) => {
            return "Error:" + err.Message;
        })
    }

    return {
        getChargeDetails: getChargeDetails,
        batchUpdate: batchUpdate,
        vslVoybatchUpdate: vslVoybatchUpdate,
        apply: apply,
        confirm: confirm,
        applyAll: applyAll,
        confirmAll: confirmAll,
        unConfirmAll: unConfirmAll,
        adjustment: adjustment,
        deleteCharge: deleteCharge,
        unConfirm: unConfirm,
        getSummary: getSummary,
        assignCommonAccount: assignCommonAccount,
        reverseCommonAccount: reverseCommonAccount,
        redirectCommonAccount: redirectCommonAccount,
        initBulkEditMenuItem: initBulkEditMenuItem,
        // searchChargeDetailDynStatus: searchChargeDetailDynStatus,
        updateCntrStatus: updateCntrStatus,
        getChargeDetailsForDeliverySummary: getChargeDetailsForDeliverySummary,
        getOutstanding: getOutstanding,
        getConVslVoy: getConVslVoy,
        validateMasterBundle: validateMasterBundle,
        searchMasterBundleVisit: searchMasterBundleVisit
    }
}