import { rfTempUnitDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputDropdown } from "veronica-ui-component/dist/component/core";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback } from "react";

export const RFTempAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    const onNumberFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        chargeDetailVM.onInputTextChange(event, 'rfTemp', chargeBatchUpdate, 'chargeBatchUpdate');
    }, [chargeDetailVM, chargeBatchUpdate]);
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <NumberInputComponent
                        label='RF Temp.'
                        width='150px'
                        fieldName={"rfTemp"}
                        value={chargeBatchUpdate.rfTemp || ''}
                        disabled={false}
                        decimalPlaces={2}
                        onChange={(e: any) =>onNumberFieldChange(e)}
                    />
                    {/*<InputField
                        label='RF Temp.'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.rfTemp || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'rfTemp', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
                </div>
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='RF Temp. Unit'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.rfTempUnit}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'rfTempUnit', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={rfTempUnitDropdownOption}/>
                </div>

            </div>
        </>
    )
}