export interface InitialMenuItemsState {
    billToCompanyEnable?: boolean | null,
    sizeEnable?: boolean | null,
    billingTypeEnable?: boolean | null,
    pooEnable?: boolean | null,
    emptyIndEnable?: boolean | null,
    operatingTmlEnable?: boolean | null,
    manualTsLegChargeIndEnable?: boolean | null,
    ignoreCntrStatusValidEnable?: boolean | null,
    dgContentEnable?: boolean | null,
    specialIndEnable?: boolean | null,
    rfTempEnable?: boolean | null,
    manualAdjEnable?: boolean | null,
    uptAbsolutelyCommonAcEnable?: boolean | null,
    copyCommonAcEnable?: boolean | null,
    adjustmentEnable?: boolean | null,
    accResumeTerminalEnable?: boolean | null,
    oneOffRateEnable?: boolean | null,
    releaseEnable?: boolean | null,
    holdEnable?: boolean | null,   
    updateVslVoyEnable?: boolean | null,
    seqEnable?: boolean | null,
    assCommonAccEnable?: boolean | null,
    reverseCommonAccEnable?: boolean | null,
    redirectCommonAccEnable?: boolean | null,
    soNumberEnable?: boolean | null,
    dischargeIndEnable?: boolean | null,
    seclegSpecialIndEnable?: boolean | null,
    seclegOperatingTmlEnable?: boolean | null,
    seclegDischargeIndEnable?: boolean | null,
    statusEnable?: boolean | null,
    status2Enable?: boolean | null,
    cntrDtlEnable?: boolean | null,
    waiverNoEnable?: boolean | null,
    permitNoEnable?: boolean | null,
    alterableBillToCompanyEnable?: boolean | null,
    alterableChargeOnCompanyEnable?: boolean | null,
    rfInfoEnable?: boolean | null,
    emptyChargeQtyEnable?: boolean | null,
    assParticipationRateEnable?: boolean | null,
    bundleMasterNoEnable?: boolean | null,
    unBundleMasterNoEnable?: boolean | null,
    tierFspEnable?: boolean | null,
    voltageEnable?: boolean | null,
    offStdMeasurementModeEnable?: boolean | null,
    marshallingTypeEnable?: boolean | null,
    specialHandlingCodeEnable?: boolean | null,
    vesselInfoEnable?: boolean | null,
    tsVesselInfoEnable?: boolean | null,
}

export const EMPTY_INITIAL_MENU_ITEMS_STATE:InitialMenuItemsState = {
    billToCompanyEnable: false,
    sizeEnable: false,
    billingTypeEnable: false,
    pooEnable: false,
    emptyIndEnable: false,
    operatingTmlEnable: false,
    manualTsLegChargeIndEnable: false,
    ignoreCntrStatusValidEnable: false,
    dgContentEnable: false,
    specialIndEnable: false,
    rfTempEnable: false,
    manualAdjEnable: false,
    uptAbsolutelyCommonAcEnable: false,
    copyCommonAcEnable: false,
    adjustmentEnable: false,
    accResumeTerminalEnable: false,
    oneOffRateEnable: false,
    releaseEnable: false,
    holdEnable: false,   
    updateVslVoyEnable: false,
    seqEnable: false,
    assCommonAccEnable: false,
    reverseCommonAccEnable: false,
    redirectCommonAccEnable: false,
    soNumberEnable: false,
    dischargeIndEnable: false,
    seclegSpecialIndEnable: false,
    seclegOperatingTmlEnable: false,
    seclegDischargeIndEnable: false,
    statusEnable: false,
    status2Enable: false,
    cntrDtlEnable: false,
    waiverNoEnable: false,
    permitNoEnable: false,
    alterableBillToCompanyEnable: false,
    alterableChargeOnCompanyEnable: false,
    rfInfoEnable: false,
    emptyChargeQtyEnable: false,
    assParticipationRateEnable: false, 
    bundleMasterNoEnable:false,
    unBundleMasterNoEnable: false,
    tierFspEnable:false,
    voltageEnable:false,
    offStdMeasurementModeEnable: false,
    marshallingTypeEnable: false,
    specialHandlingCodeEnable: false,
    vesselInfoEnable: false,
    tsVesselInfoEnable: false,
}