import { ChangeKeyDataChargeInfoEntity } from "domain/entity/ChangeKeyData/ChangeKeyDataChargeInfoEntity";
import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { EMPTY_SEARCH_MODE_ENTITY, SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSearchCriteria";
import { EMPTY_CHARGE_HEADER_ENTITY } from "domain/entity/ChargeHeader/ChargeHeaderEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customEventListener } from "helpers/Events";
import { ChargeHeaderSearchCriteria, EMPTY_CHARGE_HEADER_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/ChargeHeaderSearchCriteria";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { initalVesselSummarySearchCriteria } from "./ChargeDetailAssist";
import ChargeDetailTitleBar from "./ChargeDetailTitleBar";
import ChargeDetailHeaderPanel from "./RightPanel/ChargeDetailHeaderPanel";
import ChargeRightPanel from "./RightPanel/ChargeRightPanel";
import SummaryPanel from "./Summary/SummaryPanel";


const UserGroupComp:React.FC = () => {
    const [ chargeDetailState, setChargeDetailState ] = useChargeDetailTracked();
    const [ chargeHeaderState ] = useChargeHeaderMaintenanceTracked();
    const { isShowSummaryPanel } = chargeDetailState;
    const chargeHeaderVM = useChargeHeaderMaintenanceVM();
    const chargeDetailVM = useChargeDetailVM();
    const [initial, setInitial] = useState<boolean>(false);
    const [isLoadingChargeDetails, setIsLoadingChargeDetails] = useState<boolean>(false);

    const initialScreen = useCallback(async() => {
        if (isLoadingChargeDetails) return;

        if (!chargeHeaderState?.currentChargeHeader) return;
        chargeDetailVM.onShowLoading();

        setChargeDetailState(prevState => ({
            ...prevState,
            currentChargeHeader: chargeHeaderState?.currentChargeHeader??EMPTY_CHARGE_HEADER_ENTITY,
        }));

        try {
            setIsLoadingChargeDetails(true);
            let newSearchCriteria = {...EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA} 
            newSearchCriteria = { ...newSearchCriteria, 
                chargeHdrId: Number(chargeHeaderState?.currentChargeHeader?.id)??0, 
                states: ["E"]
            };
            const results = await Promise.allSettled([
                chargeDetailVM.loadDropdownOption(),
                chargeDetailVM.getChargeDetails(newSearchCriteria??EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA).then(data => {
                    setChargeDetailState(prevState => ({
                        ...prevState,                        
                        isShowCloseIcon: true
                    })); 
                }),
            ])
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                } else if (index === 1 && result.status === 'fulfilled') {

                }
            });
            chargeDetailVM.onHideLoading();
            setInitial(false);
        } catch(error) {
            chargeDetailVM.onHideLoading();
            setInitial(false);
        }
    }, [chargeDetailVM, chargeHeaderState?.currentChargeHeader, isLoadingChargeDetails, setChargeDetailState]);

    const initialRedirectScreenByChangeKeyData = useCallback(async() => {
        const changeKeyData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA)?
        JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA)??"") as ChangeKeyDataChargeInfoEntity :
        null;
        if (!changeKeyData) return;
        chargeDetailVM.onShowLoading();

        const chargeHeaderSearchCriteria:ChargeHeaderSearchCriteria = {
            ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
            id: Number(changeKeyData.hdrId)??0,
            hdrGroupName: "",
            tempHdrGroupName: ""
        };
        const searchModeEntity:SearchModeEntity = EMPTY_SEARCH_MODE_ENTITY;   
                    
        let newSearchCriteria = {...EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA};
        newSearchCriteria = { ...newSearchCriteria, 
            chargeHdrId: Number(changeKeyData.hdrId)??0, 
            cntrNos: [changeKeyData.cntrNo??""],
            chargeTypes: [changeKeyData.chargeType??""],
            billToCompanies: [changeKeyData.billToCompany??""],
            chargeOnCompanies: [changeKeyData.chargeOnCompany??""],
        };     

        const results = await Promise.allSettled([
            chargeDetailVM.loadDropdownOption(),
            chargeHeaderVM.onSearch(chargeHeaderSearchCriteria, searchModeEntity, true, true).then(data => {
                if (data) {
                    const chargeHdr = data[0];
                    setChargeDetailState(prevState => ({
                        ...prevState,
                        currentChargeHeader: {
                            ...prevState.currentChargeHeader,
                            ...chargeHdr
                        },
                        isShowCloseIcon: false
                    }));                
                }
            }),
            chargeDetailVM.getChargeDetails(newSearchCriteria??EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA).then(data => {
                chargeDetailVM.onHideLoading();
                setInitial(false);
            })
        ]).finally(() => {
            chargeDetailVM.onHideLoading();
            setInitial(false);
        });
        results.forEach((result, index) => {
            if (index === 0 && result.status === 'fulfilled') {

            } else if (index === 1 && result.status === 'fulfilled') {

            } else if (index === 2 && result.status === 'fulfilled') {

            }
        });
    }, [chargeDetailVM, chargeHeaderVM, setChargeDetailState]);

    const initialRedirectScreenByChargeItem = useCallback(async() => {
        const manSysCharge = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)??"") as ManualSystemChargeEntity :
            null;
        if (!manSysCharge) return;
        
        chargeDetailVM.onShowLoading();

        const chargeHeaderSearchCriteria:ChargeHeaderSearchCriteria = {
            ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
            id: Number(manSysCharge.hdrId)??0,
            hdrGroupName: ""
        };
        const searchModeEntity:SearchModeEntity = EMPTY_SEARCH_MODE_ENTITY;   
                    
        let newSearchCriteria = {...EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA};
        newSearchCriteria = { ...newSearchCriteria, 
            chargeHdrId: Number(manSysCharge.hdrId)??0, 
            cntrNos: [manSysCharge.cntrNo??""]
        };     

        const results = await Promise.allSettled([
            chargeDetailVM.loadDropdownOption(),
            chargeHeaderVM.onSearch(chargeHeaderSearchCriteria, searchModeEntity, true, true).then(data => {
                if (data) {
                    const chargeHdr = data[0];
                    setChargeDetailState(prevState => ({
                        ...prevState,
                        currentChargeHeader: {
                            ...prevState.currentChargeHeader,
                            ...chargeHdr
                        },
                        isShowCloseIcon: false
                    }));                
                }
            }),
            chargeDetailVM.getChargeDetails(newSearchCriteria??EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA).then(data => {
                chargeDetailVM.onHideLoading();
                setInitial(false);
            })
        ]).finally(() => {
            chargeDetailVM.onHideLoading();
            setInitial(false);
            localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH);
        });
        results.forEach((result, index) => {
            if (index === 0 && result.status === 'fulfilled') {

            } else if (index === 1 && result.status === 'fulfilled') {

            } else if (index === 2 && result.status === 'fulfilled') {

            }
        });
        
    }, [chargeDetailVM, chargeHeaderVM, setChargeDetailState]);

    const initialRedirectScreenByVesselSummary = useCallback(async() => {
        const vesselSummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY)??"") :
            null;
        const inboundSummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY)??"") :
            null;
        if (!vesselSummaryData && !inboundSummaryData) return;
        
        chargeDetailVM.onShowLoading();

        const chargeHeaderSearchCriteria:ChargeHeaderSearchCriteria = {
            ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
            id: Number((vesselSummaryData??inboundSummaryData)?.chargeHdrId)??0,
            hdrGroupName: ""
        };
        const searchModeEntity:SearchModeEntity = EMPTY_SEARCH_MODE_ENTITY;   
                    
        let newSearchCriteria = initalVesselSummarySearchCriteria(vesselSummaryData??inboundSummaryData);        

        const results = await Promise.allSettled([
            chargeDetailVM.loadDropdownOption(),
            chargeHeaderVM.onSearch(chargeHeaderSearchCriteria, searchModeEntity, true, true).then(data => {
                if (data) {
                    const chargeHdr = data[0];
                    setChargeDetailState(prevState => ({
                        ...prevState,
                        currentChargeHeader: {
                            ...prevState.currentChargeHeader,
                            ...chargeHdr
                        },
                        isShowCloseIcon: false
                    }));
                }
            }),            
    
            chargeDetailVM.getChargeDetails(newSearchCriteria??EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA).then(data => {
                chargeDetailVM.onHideLoading();
                setInitial(false);
            }),
        ]).finally(() => {
            chargeDetailVM.onHideLoading();
            setInitial(false);
        });
        results.forEach((result, index) => {
            if (index === 0 && result.status === 'fulfilled') {

            } else if (index === 1 && result.status === 'fulfilled') {

            } else if (index === 2 && result.status === 'fulfilled') {

            }
        });
        
    }, [chargeDetailVM, chargeHeaderVM, setChargeDetailState]);

    const initialRedirectScreenByDeliverySummary = useCallback(async() => {
        const deliveryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY)??"") :
            null;
        if (!deliveryData) return;
        
        chargeDetailVM.onShowLoading();

        let chargeHeaderSearchCriteria:ChargeHeaderSearchCriteria = {
            ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
            id: Number((deliveryData??deliveryData)?.chargeHdrId)??0,
            hdrGroupName: "SHIPBOUNDEDINPERIOD",
            tempHdrGroupName: "SHIPBOUNDEDINPERIOD",
            type: "deliveryChargeSummary"
            
        };
        const searchModeEntity:SearchModeEntity = EMPTY_SEARCH_MODE_ENTITY; 
    
        const results = await Promise.allSettled([
            chargeDetailVM.loadDropdownOption(),
            chargeHeaderVM.onSearch(chargeHeaderSearchCriteria, searchModeEntity, true, true).then(data => {
                if (data) {
                    const chargeHdr = data[0];
                    setChargeDetailState(prevState => ({
                        ...prevState,
                        currentChargeHeader: {
                            ...prevState.currentChargeHeader,
                            ...chargeHdr
                        },
                        isShowCloseIcon: false
                    }));
                }
            }),            
    
            chargeDetailVM.getChargeDetails(deliveryData??EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA).then(data => {
                chargeDetailVM.onHideLoading();
                setInitial(false);
            }),
        ]).finally(() => {
            chargeDetailVM.onHideLoading();
            setInitial(false);
        });
        results.forEach((result, index) => {
            if (index === 0 && result.status === 'fulfilled') {

            } else if (index === 1 && result.status === 'fulfilled') {

            } else if (index === 2 && result.status === 'fulfilled') {

            }
        });
        
    }, [chargeDetailVM, chargeHeaderVM, setChargeDetailState]);

    useEffect(() => {
        const onChargeDetailRedirect = async(e:any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH:                                        
                    const manSysCharge = (e.detail.data.data as ManualSystemChargeEntity);
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH, JSON.stringify(manSysCharge));
                    setInitial(true);                  
                    
                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY:                                        
                    const vesselData = e.detail.data.data;
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY, JSON.stringify(vesselData));
                    setInitial(true);                  
                    
                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY:                                        
                    const inboundData = e.detail.data.data;
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY, JSON.stringify(inboundData));
                    setInitial(true);                  
                    
                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA:                                        
                    const changeKeyData = e.detail.data.data;
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA, JSON.stringify(changeKeyData));
                    setInitial(true);                  
                    
                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY:                                        
                    const deliveryData = e.detail.data.data;
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY, JSON.stringify(deliveryData));
                    setInitial(true);                  
                    
                    break;
            }
        }

        customEventListener(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT_INITIAL, onChargeDetailRedirect);
    }, [chargeDetailVM, setChargeDetailState, chargeHeaderVM]);

    useEffect(() => {
        const manSysCharge = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)??"") as ManualSystemChargeEntity :
            null;
        const vesselSummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY)??"") :
            null;
        const inboundSummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY)??"") :
            null;
        const changeKeyData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA)??"") :
            null;
        const deliveryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY)??"") :
            null;
        if (!manSysCharge && !vesselSummaryData && !inboundSummaryData && !changeKeyData && !deliveryData) {                
            initialScreen();
        } else {                
            initialRedirectScreenByChargeItem();
            initialRedirectScreenByVesselSummary();
            initialRedirectScreenByChangeKeyData();
            initialRedirectScreenByDeliverySummary();
        }

    }, [chargeDetailVM, chargeHeaderVM, initial, setChargeDetailState, initialRedirectScreenByChargeItem, initialScreen, 
        initialRedirectScreenByVesselSummary, initialRedirectScreenByChangeKeyData, initialRedirectScreenByDeliverySummary]);

    return <>
        <div className={`main-comp-wrapper`}>
            {/* User table panel */}
            <ChargeDetailTitleBar/>
            {/* User editable panel 
                left: show summary
                right: show table and search/batch update*/}
            <ChargeDetailHeaderPanel/>

            {chargeDetailState.isLoading && <Loader Indicator="Stripe" size="Large" /> }

            <div className={`main-comp-wrapper${isShowSummaryPanel ? '' : ' im-hide-side-form-draggable'}`}>
                <SliderPanel
                    isOpen={true}
                    draggable={true}
                    leftSectionWidth={isShowSummaryPanel?"25%":"0%"}
                    rightSectionWidth={isShowSummaryPanel?"75%":"100%"}
                    leftChildren={<SummaryPanel/>}
                    rightChildren={<ChargeRightPanel/>}
                    />
            </div>    
        </div>
    </>
}

export default memo(UserGroupComp);