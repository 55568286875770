import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeDetailSearchCriteria, EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSearchCriteria";
import { ChargeDetailSeqCriteria, EMPTY_CHARGE_DETAIL_SEQ_CRITERIA_ENTITY } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSeqCriteria";
import { ValidateMasterBundleRequest } from "domain/entity/ChargeDetailMaintenance/ValidateMasterBundleRequest";
import { VesselVoyageMasterEntity } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterEntity";
import { EMPTY_VESSEL_VOYAGE_MASTER_SEARCH_CRITERIA, VesselVoyageMasterSearchCriteria } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterSearchCriteria";
import { ChargeHeaderEntity } from "domain/entity/ChargeHeader/ChargeHeaderEntity";
import { DeliveryChargeSummaryEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ChargeDetailRepository } from "domain/repository/ChargeDetailMaintenance/ChargeDetailRepo";
import { ChargeDetailSeqCriteriaRepository } from "domain/repository/ChargeDetailMaintenance/ChargeDetailSeqCriteriaRepo";
import { ConsortiumRepository } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepo";
import { VesselVoyageMasterRepository } from "domain/repository/ChargeDetailMaintenance/VesselVoyageMasterRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { ForwarderCodeRepository } from "domain/repository/ForwarderCode/ForwarderCodeRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import { PhysicalHandlingCodeRepository } from "domain/repository/PhysicalHandlingCode/PhysicalHandlingCodeRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import { SpecialIndRepository } from "domain/repository/SpecialInd/SpecialIndRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TerminalRepository } from "domain/repository/Terminal/TerminalRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { ChargeBatchUpdateEntity, EMPTY_CHARGE_BATCH_UPDATE_ENTITY } from "presentation/constant/ChargeDetailMaintenance/ChargeBatchUpdateEntity";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { updateAccountTerminalValidationSchema, updateAdjustmentValidationSchema, updateBundleMasterValidationSchema, updateOneOffRateValidationSchema } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailValidationSchema";
import { EMPTY_INITIAL_MENU_ITEMS_STATE, InitialMenuItemsState } from "presentation/constant/ChargeDetailMaintenance/InitialMenuItemsState";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { Validation } from "presentation/constant/Validation";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { ChargeHeaderMaintenanceModel } from "presentation/model/ChargeHeaderMaintenance/ChargeHeaderMaintenanceModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { setChargeBulkMenuMark } from "presentation/view/section/ChargeDetailMaintenance/BulkEditAssist";
import { updateButtonState, validateChargeDelete } from "presentation/view/section/ChargeDetailMaintenance/ChargeDetailAssist";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";


interface ChargeDetailVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChargeDetailModel>> | ((value: SetStateAction<ChargeDetailModel>) => void),
    ],
    headerDispatch: [
        Dispatch<SetStateAction<ChargeHeaderMaintenanceModel>> | ((value: SetStateAction<ChargeHeaderMaintenanceModel>) => void),
    ],
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    parameterDetailRepo: ParameterDetailRepository,
    specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    chargeDetailRepo: ChargeDetailRepository,
    vesselVoyageMasterRepo: VesselVoyageMasterRepository,
    consortiumRepo: ConsortiumRepository,
    forwarderCodeRepo: ForwarderCodeRepository,
    opsLineRepo: OpsLineRepository,
    terminalRepo: TerminalRepository,
    physicalHandlingCodeRepo: PhysicalHandlingCodeRepository,
    specialIndRepo: SpecialIndRepository,
    chargeDetailSeqCriteriaRepo: ChargeDetailSeqCriteriaRepository,
    masterDataRepo: MasterDataRepository,
}

export const ChargeDetailVM = ({dispatch, headerDispatch, companyRepo, chargeTypeRepo, standardTariffCodeRepo, parameterDetailRepo, specialhandlingIndMappingRepo,
    chargeDetailRepo, vesselVoyageMasterRepo, consortiumRepo, forwarderCodeRepo, opsLineRepo,terminalRepo,physicalHandlingCodeRepo,specialIndRepo, 
    chargeDetailSeqCriteriaRepo, masterDataRepo
}:ChargeDetailVMProps) => {
    const [chargeDetailDispatch] = dispatch;
    const [chargeHeaderDispatch] = headerDispatch;

    //state handle
    const onCloseClick = () => {
        chargeHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowContainerDetail: false,
                isRedirectFromChargeDetail: true,
            }
        }); 
    }
    
    const onBatchUpdateCloseClick = () => {
        chargeDetailDispatch(prevState => {
            const newChargeBatchUpdate = setChargeBulkMenuMark(prevState.selectedChargeDetails);
            
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isAccTml:false,
                isAssignOneOffRate:false,
                isUptAbsolutelyCommonAc:false,
                isShowRightEditPanel: false,
                isBatchUpdate: false,              

                chargeBatchUpdate: {
                    ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY,
                    ...newChargeBatchUpdate,
                }
            }
        });        
    }

    const onCntrInfoCloseClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: false
            }
        });        
    }

    const onAssignSeqNoCloseClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: false
            }
        });        
    }

    const onVslVoyCloseClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowRightEditPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isBatchUpdate: false
            }
        });        
    }

    const onBulkEditClick = (selectedCharges: ChargeDetailEntity[] | null) => {
        chargeDetailDispatch(prevState => {
            //get the bulk edit state
            const newBatchUpdate = setChargeBulkMenuMark(selectedCharges);
            
            return {
                ...prevState,
                isShowBatchUpdatePanel: true,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true,
                chargeBatchUpdate: {
                    ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY, 
                    ...newBatchUpdate,
                    bulkEditAction: ChargeDetailConstant.Charge.BULK_EDIT,
                    accResumeTerminalEnable: false,
                    oneOffRateEnable: false,
                    uptAbsolutelyCommonAcEnable: false,
                    copyCommonAcEnable: false,
                    adjustmentEnable: false,                    
                }
            }
        })
    }

    //add assign/resume one-off rate action
    const onOneOffRateClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: true,
                isAssignOneOffRate:true,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true,
                chargeBatchUpdate: {
                    ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY, 
                    oneOffRateInd: "Y",
                    accResumeTerminalEnable: false,
                    oneOffRateEnable: true,
                    uptAbsolutelyCommonAcEnable: false,
                    copyCommonAcEnable: false,
                    adjustmentEnable: false,
                }
            }
        })
    }

    const onUnbundleClick = async() => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,                
                chargeBatchUpdate: {
                    ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY, 
                    unBundleMasterNoActive: true,
                    bundleMasterNumber: "",
                    bundleMasterVisit: ""
                }
            }
        })
    }

    //add acc/resume terminal action
    const onAccTmlClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: true,
                isAccTml:true,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true,
                chargeBatchUpdate: {
                    ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY, 
                    accResumeTerminalEnable: true,
                    accResumeTerminalInd: "Y",
                    uptAbsolutelyCommonAcEnable: false,
                    copyCommonAcEnable: false,
                    adjustmentEnable: false,
                }
            }
        })
    }

    const onUpdateCntrInfoClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: true,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true
            }
        })
    }

    const onAssignSeqNoClick = (searchSeqCriteria: ChargeDetailSeqCriteria) => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: true,
                isShowRightEditPanel: false,
                isBatchUpdate: true
            }
        })
    }

    const onUpdateCntrStatusClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true,
                isShowUpdateStatus: true,
            }
        })
    }

    const onUpdateVslVoySearchClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: true,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true
            }
        })
    }

    const onUpdateVslVoyTableClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: false,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: true,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true
            }
        })
    }

    const onSearchCriteriaResetClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA
            }
        })
    }

    
    const onVslVoySearchCriteriaResetClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                searchVslVoyCriteria: {
                    ...prevState.searchVslVoyCriteria,
                    ...EMPTY_VESSEL_VOYAGE_MASTER_SEARCH_CRITERIA
                }
            }
        })
    }

    const onAdjustmentClick = async(chargeList: ChargeDetailEntity[]) => {        
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: true,
                isAdjustment:true,
                isShowCntrInfoPanel:false,
                isShowUpdateVesselVoyageMasterSearchPanel:false,
                isShowUpdateVesselVoyageMasterTablePanel:false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true,
                // selectedChargeDetails:[],
                chargeBatchUpdate: {
                    ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY, 
                    adjustmentEnable:true,
                    oneOffRateEnable: false,
                    uptAbsolutelyCommonAcEnable: false,
                    copyCommonAcEnable: false,
                }
            }
        });
    }

    const onVslVoyApplyClick = async(voyageList: VesselVoyageMasterEntity[], chargeList: ChargeDetailEntity[]) => {
        
        if (voyageList.length !== 1) {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"updateNewVesselVoyageFail": "Please select a record."}
                }
            });
        }
        
        await chargeDetailRepo.vslVoybatchUpdate(voyageList[0], chargeList).then(data => {
            if (data) {
                chargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"updateNewVesselVoyageSuccess": `${MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace("{operationDone}", ChargeDetailConstant.Charge.UPDATE_VESSEL_VOYAGE)}`}
                    }
                });
            }
        }).catch(error => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"updateNewVesselVoyageFail": `${MessageConstant.common.OPERATION_DONE_Failure.replace("{operationDone}", ChargeDetailConstant.Charge.UPDATE_VESSEL_VOYAGE)}`}
                }
            });
        })
        
    }

    const onShowLoading = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }
    //state handle

    //data hanlde
    //load data
    const getChargeDetails = async(searchCriteria: ChargeDetailSearchCriteria, forNormalChargeSearch:boolean=true) => {
        if (forNormalChargeSearch) {
            chargeDetailDispatch(prevState => ({
                ...prevState,
                chargeDetails: [],
                selectedChargeDetails: [],
                refreshBedge:true,
                isRefreshButton:true,
            }));
        } else {
            chargeDetailDispatch(prevState => ({
                ...prevState,
                allChargeDetails: [],
                selectedChargeDetails: [],
                refreshBedge:true,
                isRefreshButton:false,
            }));
        }
        await chargeDetailRepo.getChargeDetails(searchCriteria).then((data) => {
            if (forNormalChargeSearch) {
                chargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        chargeDetails: data??[],
                        chargeBatchUpdate: EMPTY_CHARGE_BATCH_UPDATE_ENTITY,
                        selectedChargeDetails: [],
                        allChargeDetails: [],
                        searchCriteria: {
                            ...prevState.searchCriteria,
                            ...searchCriteria,
                        },

                        isDisableAdjustment: !!prevState.selectedChargeDetails,
                        isDisableApply: !!prevState.selectedChargeDetails,
                        isDisableConfirm: !!prevState.selectedChargeDetails,
                        isDisableDelete: !!prevState.selectedChargeDetails,
                        isDisableUnconfirm: !!prevState.selectedChargeDetails,  
                    }
                })
            } else {
                chargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        chargeDetails: data??[],
                        chargeBatchUpdate: EMPTY_CHARGE_BATCH_UPDATE_ENTITY,
                        selectedChargeDetails: [],
                        searchCriteria: {
                            ...prevState.searchCriteria,
                        },

                        isDisableAdjustment: !!prevState.selectedChargeDetails,
                        isDisableApply: !!prevState.selectedChargeDetails,
                        isDisableConfirm: !!prevState.selectedChargeDetails,
                        isDisableDelete: !!prevState.selectedChargeDetails,
                        isDisableUnconfirm: !!prevState.selectedChargeDetails,  
                    }
                })
            }
        }).catch((error) => {
            return [];
        });
    }
    const getChargeDetailsForDeliverySummary = async(deliveryChargeSummary: DeliveryChargeSummaryEntity) => {
        return await chargeDetailRepo.getChargeDetailsForDeliverySummary(deliveryChargeSummary).then((data) => {
            return data;
        }).catch((error) => {
            return null;
        });
    }

    const getVesselVoyageMaster = async(searchCriteria: VesselVoyageMasterSearchCriteria) => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                vesselVoyageMasters: [],
                selectedVesselVoyageMasters: []
            }
        })
        await vesselVoyageMasterRepo.getVesselVoyageMaster(searchCriteria).then((data) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    vesselVoyageMasters: data
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const loadDropdownOption = async() => {
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                        chgOnCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        );

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions:DropdownProps[] = [];
                let subChargeTypeDropdownOptions:{[key:string]: DropdownProps[]} = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOptions[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOptions[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOptions[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chgTypeDropdownOptions: chargeTypeDropdownOptions,
                        subChgTypeDropdownOptions: subChargeTypeDropdownOptions
                    }
                }))
            }
        )
        
        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tarCodes => {
                const tarCodeDropdownOptions = tarCodes.map((result) => ({
                        dropdownLabel: result.tariffCode,
                        tagLabel: result.tariffCode,
                        value: result.tariffCode,
                })) ?? [];

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tarCodeDropdownOptions: tarCodeDropdownOptions,
                    }
                }))
            }
        )
        
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_TYPE").then(
            cntrTypes => {
                const cntrTypeDropdownOptions = cntrTypes?.map((cntrType) => ({
                    dropdownLabel: cntrType.parameterDtlCode,
                    tagLabel: cntrType.parameterDtlCode,
                    value: cntrType.parameterDtlCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        typeDropdownOptions: cntrTypeDropdownOptions,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_SIZE").then(
            cntrSizes => {
                const containerSizeDropdownOptions = cntrSizes?.map((containerSize) => ({
                    dropdownLabel: containerSize.parameterDtlCode,
                    tagLabel: containerSize.parameterDtlCode,
                    value: containerSize.parameterDtlCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        sizeGroupDropdownOptions: containerSizeDropdownOptions
                    }
                }))
            }
        )

        await specialhandlingIndMappingRepo.getAllSpecialHandlingIndMappings().then(
            specialHandlingCodeList => {
                const specialHandlingCodeDropdownOptions = specialHandlingCodeList?.map((specialHandlingCode) => ({
                    dropdownLabel: specialHandlingCode.specialHandlingCode,
                    tagLabel: specialHandlingCode.specialHandlingCode,
                    value: specialHandlingCode.specialHandlingCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specHandingCodeDropdownOptions: specialHandlingCodeDropdownOptions,
                        specHandingCodeD1DropdownOptions: specialHandlingCodeDropdownOptions,

                    }
                }))
            }
        )
        
        await opsLineRepo.getAllOpsLines().then(
            opsLines => {
                const opsLinesDropdownOptions = opsLines?.map((opsLineEntity) => ({
                    dropdownLabel:opsLineEntity.opsLine,
                    tagLabel: opsLineEntity.opsLine,
                    value: opsLineEntity.opsLine,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        opsLineDropdownOptions: opsLinesDropdownOptions
                    }
                }))
            }
        )

        await forwarderCodeRepo.getAllForwarderCodes().then(
            forwarderCodes => {
                const forwarderCodesDropdownOptions = forwarderCodes?.map((forwarderCodeEntity) => ({
                    dropdownLabel:forwarderCodeEntity.forwarderCode,
                    tagLabel: forwarderCodeEntity.forwarderCode,
                    value: forwarderCodeEntity.forwarderCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        forwarderCodeDropdownOptions: forwarderCodesDropdownOptions
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CHARGE_MAINT_HANDLING_STATUS").then(
            statusCokdList => {
                const statusCokdDropdownOptions = statusCokdList?.map((statusCokd) => ({
                    dropdownLabel: statusCokd.parameterDtlCode,
                    tagLabel: statusCokd.parameterDtlCode,
                    value: statusCokd.parameterDtlCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        statusCOKDDropdownOptions: statusCokdDropdownOptions
                    }
                }))
            }
        )
        await terminalRepo.getTerminalsForComboBox().then(
            terminalList => {
                const terminalDropdownOptions = terminalList?.map((terminal) => ({
                    dropdownLabel: terminal.terminalCode,
                    tagLabel: terminal.terminalCode,
                    value: terminal.terminalCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: terminalDropdownOptions
                    }
                }))
            }
        )
        await physicalHandlingCodeRepo.getPhysicalHandlingCodeForComboBox().then(
            physicalHandlingCodeList => {
                const physicalHandlingCodeDropdownOptions = physicalHandlingCodeList?.map((physicalHandlingCode) => ({
                    dropdownLabel: physicalHandlingCode.physicalHandlingCode,
                    tagLabel: physicalHandlingCode.physicalHandlingCode,
                    value: physicalHandlingCode.physicalHandlingCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeCodeDropdownOptions: physicalHandlingCodeDropdownOptions
                    }
                }))
            }
        )
        await specialIndRepo.getSpecialIndForComboBox().then(
            specialIndList => {
                const specialIndDropdownOption = specialIndList?.map((specialInd) => ({
                    dropdownLabel: specialInd.specialInd,
                    tagLabel: specialInd.specialInd,
                    value: specialInd.specialInd,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specialIndDropdownOptions: specialIndDropdownOption
                    }
                }))
            }
        )
        //get marshallingType DropdownOption from parameter
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("MARSHALLING_TYPE").then(
            marshallingTypeList => {
                const marshallingTypeDropdownOption = marshallingTypeList?.map((marshallingType) => ({
                    dropdownLabel: marshallingType.parameterDtlCode,
                    tagLabel: marshallingType.parameterDtlCode,
                    value: marshallingType.parameterDtlCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshallingTypeDropdownOptions: marshallingTypeDropdownOption
                    }
                }))
            }
        )
        //get accResumeTerminalReasonCode DropdownOption from parameter
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("ACCT_TML_REASON_CODE").then(
            accReasonCodeList => {
                const accReasonCodeDropdownOption = accReasonCodeList?.map((reasonCode) => ({
                    dropdownLabel: reasonCode.parameterDtlCode,
                    tagLabel: reasonCode.parameterDtlCode,
                    value: reasonCode.parameterDtlCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reasonCodeDropdownOptions: accReasonCodeDropdownOption
                    }
                }))
            }
        )
        //get UOM DropdownOption from parameter
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("UOM").then(
            uomList => {
                const uomDropdownOption = uomList?.map((uom) => ({
                    dropdownLabel: uom.parameterDtlCode,
                    tagLabel: uom.parameterDtlCode,
                    value: uom.parameterDtlCode,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        uomDropdownOptions: uomDropdownOption
                    }
                }))
            }
        )

        await consortiumRepo.getAllConsortiums().then(
            consortium => {
                let consortiumCodeDropdownOptions = consortium?.map((item) => ({
                    dropdownLabel: item.consortiumCode,
                    tagLabel: item.consortiumCode,
                    value: item.consortiumCode,
                })) ?? []
                consortiumCodeDropdownOptions = _.orderBy(consortiumCodeDropdownOptions, "dropdownLabel");

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: consortiumCodeDropdownOptions,
                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode)=>({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                chargeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeOptions: [
                            ...serviceCodeDropdownOptions]
                    }
                }))
            }           
        );
    }

    const onSearchClick = async() => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel,
                isShowSummaryPanel: false
            }
        });       
    }

    const onSummryClick = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowSummaryPanel: !prevState.isShowSummaryPanel,
                isShowCriteriaPanel: false
            }
        });      
    }

    const getChargeDetailSummary = async(chargeHeader: ChargeHeaderEntity, returnData:boolean=false) => {        
        return await chargeDetailRepo.getSummary(chargeHeader).then((data) => {
            if (data) {
                if (!returnData) {
                    //calculate the total amount
                    const totals: { [key: string]: number } = {};

                    for (const key in data) {
                        const layer = data[key];
                        for (const subKey in layer) {
                            const value = parseInt(layer[subKey], 10);
                            if (!isNaN(value)) {
                                if (!totals[subKey]) {
                                totals[subKey] = 0;
                                }
                                totals[subKey] += value;
                            }
                        }
                    }
                    const stringTotals: { [key: string]: string } = {};
                    for (const subKey in totals) {
                        stringTotals[subKey] = totals[subKey].toString();
                    }

                    data = {...data, "totals": stringTotals}
                    
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            chargeDetailSummary: data
                        }
                    })
                } else {
                    return data;
                }
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {                
                return {
                    ...prevState,
                    allFormState: {"getSummaryFail": error.message}
                }
            })
        })  
    }

    const getChargeDetailOutstanding = async(chargeHeader: ChargeHeaderEntity) => {        
        return await chargeDetailRepo.getOutstanding(chargeHeader).then((data) => {
            chargeDetailDispatch(prevState => {                
                return {
                    ...prevState,
                    refreshBedge: false,
                    isRefreshButton:false,
                }
            })
            if (data) {
                return data;
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {                
                return {
                    ...prevState,
                    allFormState: {"getOutstandingFail": error.message},
                    refreshBedge: false,
                    isRefreshButton:false,
                }
            })
        })  
    }

    const onCloseSummary = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowSummaryPanel: false
            }
        })
    }

    //batch update
    const onBatchUpdateSave = async(batchUpdateEntity:ChargeBatchUpdateEntity, chargeList:ChargeDetailEntity[], keepBatchUpdatePanel:boolean=false) => {
        if (chargeList && chargeList.length > 0 && batchUpdateEntity) {
            chargeList = chargeList.map((currCharge) => {
                let charge = {...currCharge};

                if (batchUpdateEntity.copyCommonAcActive) {
                    if(batchUpdateEntity.companyCode){
                        return ({
                            ...charge,
                            companyCode: batchUpdateEntity.companyCode,
                            copyCommonAcInd: "Y",
                        });
                    }
                    return charge;
                }
                if (batchUpdateEntity.uptAbsolutelyCommonAcActive) {
                    if(batchUpdateEntity.cmAcFixQty){
                        return ({
                            ...charge,
                            cmAcFixQty: batchUpdateEntity.cmAcFixQty,
                        });
                    }
                    return charge;
                }
                if (batchUpdateEntity.accResumeTerminalActive) {
                    if(batchUpdateEntity.reasonCode){
                        return ({
                            ...charge,
                            accResumeTerminalInd: batchUpdateEntity.accResumeTerminalInd,
                            accountTmlReasonCode: batchUpdateEntity.reasonCode,
                        });
                    }else{
                        return ({
                            ...charge,
                            accResumeTerminalInd: batchUpdateEntity.accResumeTerminalInd,
                        });
                    }
                }
                if (batchUpdateEntity.holdActive) {
                    return ({
                        ...charge,
                        holdInd: batchUpdateEntity.holdInd,
                    });
                }
                if (batchUpdateEntity.releaseActive) {
                    return ({
                        ...charge,
                        releaseInd: batchUpdateEntity.releaseInd,
                    });
                }
                if (batchUpdateEntity.billToCompanyActive) {
                    if(batchUpdateEntity.billToCompany){
                        charge.billToCompany = batchUpdateEntity.billToCompany;
                    }
                    if (batchUpdateEntity.chargeOnCompany) {
                        charge.chargeOnCompany = batchUpdateEntity.chargeOnCompany;
                    }
                    if (batchUpdateEntity.alterableBillToCompany) {
                        charge.alterableBillToCompany = batchUpdateEntity.alterableBillToCompany;
                    }
                    if (batchUpdateEntity.alterableChargeOnCompany) {
                        charge.alterableChargeOnCompany = batchUpdateEntity.alterableChargeOnCompany;
                    }
                }
                if (batchUpdateEntity.oneOffRateActive) {

                    if(batchUpdateEntity.rate && batchUpdateEntity.reasonCode){
                        charge.rate = batchUpdateEntity.rate;
                        charge.reasonCode = batchUpdateEntity.reasonCode;
                        charge.oneOffRateInd = batchUpdateEntity.oneOffRateInd;
                    }else{
                        charge.oneOffRateInd = batchUpdateEntity.oneOffRateInd;;
                    }
                }
                
                if (batchUpdateEntity.pooActive) {
                    if(batchUpdateEntity.poo){
                        charge.poo = batchUpdateEntity.poo;
                    }
                    if (batchUpdateEntity.pol) {
                        charge.pol = batchUpdateEntity.pol;
                    }
                    if (batchUpdateEntity.pod) {
                        charge.pod = batchUpdateEntity.pod;
                    }
                    if (batchUpdateEntity.fd) {
                        charge.fd = batchUpdateEntity.fd;
                    }
                }
                if (batchUpdateEntity.sizeActive) {
                    if(batchUpdateEntity.size){
                        charge.size = batchUpdateEntity.size;
                    }
                    if (batchUpdateEntity.cntrType) {
                        charge.cntrType = batchUpdateEntity.cntrType;
                    }
                }
                if (batchUpdateEntity.statusActive && batchUpdateEntity.status) {
                    charge.status = batchUpdateEntity.status;
                }
                if (batchUpdateEntity.billingTypeActive && batchUpdateEntity.billingType) {
                    charge.billingType = batchUpdateEntity.billingType;
                }
                if (batchUpdateEntity.emptyInd) {
                    charge.emptyIndicator = batchUpdateEntity.emptyInd;
                    charge.updateEmptyIndicator = true;
                }
                if (batchUpdateEntity.operatingTmlActive && batchUpdateEntity.operatingTml) {
                    charge.operatingTml = batchUpdateEntity.operatingTml;
                }
                if (batchUpdateEntity.manualTsLegChargeIndActive && batchUpdateEntity.manualTsLegChargeInd) {
                    charge.manualTsLegChargeInd = batchUpdateEntity.manualTsLegChargeInd;
                }
                if (batchUpdateEntity.ignoreCntrStatusValidActive && batchUpdateEntity.ignoreCntrStatusValid) {
                    charge.ignoreCntrStatusValidation = batchUpdateEntity.ignoreCntrStatusValid;
                }
                // if (batchUpdateEntity.adjustmentActive && batchUpdateEntity.adjustmentType) {
                //     charge.adjustmentType = batchUpdateEntity.adjustmentType;
                //     charge.reasonCode = batchUpdateEntity.adjustmentReasonCode;
                // }
                if (ChargeDetailConstant.Charge.UPDATE_CONTAINER_INFO === batchUpdateEntity.bulkEditAction) {
                    charge.updateCntrInfo = true;
                }
                if (batchUpdateEntity.cntrTspStatus) {
                    charge.cntrTspStatus = batchUpdateEntity.cntrTspStatus;
                }
                if (batchUpdateEntity.imcoCode1) {
                    charge.imcoCode = batchUpdateEntity.imcoCode1;
                }
                if (batchUpdateEntity.imcoCode2) {
                    charge.imcoCode2 = batchUpdateEntity.imcoCode2;
                }
                if (batchUpdateEntity.imcoCode3) {
                    charge.imcoCode3 = batchUpdateEntity.imcoCode3;
                }
                if (batchUpdateEntity.olDimen) {
                    charge.olDimen = batchUpdateEntity.olDimen;
                }
                if (batchUpdateEntity.owDimen) {
                    charge.owDimen = batchUpdateEntity.owDimen;
                }
                if (batchUpdateEntity.ohDimen) {
                    charge.ohDimen = batchUpdateEntity.ohDimen;
                }
                if (batchUpdateEntity.cntrType) {
                    charge.cntrType = batchUpdateEntity.cntrType;
                }
                if (batchUpdateEntity.rfTemp) {
                    charge.rfTemp = batchUpdateEntity.rfTemp;
                }
                if (batchUpdateEntity.rfTempUnit) {
                    charge.rfTempUnit = batchUpdateEntity.rfTempUnit;
                }
                if (batchUpdateEntity.grossWt){
                    charge.grossWt = batchUpdateEntity.grossWt;
                }
                if (batchUpdateEntity.forwarderCode1){
                    charge.forwarderCode1 = batchUpdateEntity.forwarderCode1;
                }
                if (batchUpdateEntity.reference){
                    charge.reference = batchUpdateEntity.reference;
                }
                if (batchUpdateEntity.dischargeDatetime){
                    charge.dischargeDatetime = batchUpdateEntity.dischargeDatetime;
                }
                if (batchUpdateEntity.gateOutDatetime){
                    charge.gateOutDatetime = batchUpdateEntity.gateOutDatetime;
                }
                if (batchUpdateEntity.deliveryDatetime){
                    charge.deliveryDate = batchUpdateEntity.deliveryDatetime;
                }
                if (batchUpdateEntity.receiveDatetime){
                    charge.receiveDateTime = batchUpdateEntity.receiveDatetime;
                }
                if (batchUpdateEntity.receivalCutoffDatetime){
                    charge.obCntrCloseDatetime = batchUpdateEntity.receivalCutoffDatetime;
                }
                if (batchUpdateEntity.gateInDatetime){
                    charge.gateInDatetime = batchUpdateEntity.gateInDatetime;
                }
                if (batchUpdateEntity.loadingDatetime){
                    charge.loadingDatetime = batchUpdateEntity.loadingDatetime;
                }
                
                if (batchUpdateEntity.dgContentActive) {
                    if(batchUpdateEntity.dgContent){
                        charge.dgContent = batchUpdateEntity.dgContent;
                    }
                    if (batchUpdateEntity.duContent) {
                        charge.duContent = batchUpdateEntity.duContent;
                    }
                }
                if (batchUpdateEntity.specialIndtActive) {
                    if(batchUpdateEntity.specialInd){
                        charge.specialInd = batchUpdateEntity.specialInd;
                    }
                    if(batchUpdateEntity.forwarderReference){
                        charge.forwarderReference = batchUpdateEntity.forwarderReference;
                    }
                }
                if (batchUpdateEntity.manualAdjActive) {
                    if(batchUpdateEntity.manualAdjChargeQty){
                        charge.manualAdjChargeQty = batchUpdateEntity.manualAdjChargeQty;
                    }
                    if(batchUpdateEntity.manualAdjChargeQtyUom){
                        charge.manualAdjChargeQtyUom = batchUpdateEntity.manualAdjChargeQtyUom;
                    }
                    if(batchUpdateEntity.reasonCode){
                        charge.reasonCode = batchUpdateEntity.reasonCode;
                    }
                }
                if (batchUpdateEntity.consortiumCode) {
                    charge.consortiumCode = batchUpdateEntity.consortiumCode;
                }
                if (batchUpdateEntity.vesselCode) {
                    charge.vesselCode = batchUpdateEntity.vesselCode;
                }
                if (batchUpdateEntity.voyageCode) {
                    charge.voyageCode = batchUpdateEntity.voyageCode;
                }
                if (batchUpdateEntity.vesselCode) {
                    charge.vesselName = batchUpdateEntity.vesselName;
                }
                if(batchUpdateEntity.soNumberActive){
                    if(batchUpdateEntity.soNumber){
                        charge.soNumber1 =  batchUpdateEntity.soNumber;
                    }
                }
                if(batchUpdateEntity.waiverNoActive){
                    if(batchUpdateEntity.waiverNumber) {
                        charge.waiverNo = batchUpdateEntity.waiverNumber;
                    }
                }
                if(batchUpdateEntity.dischargeIndActive){
                    if(batchUpdateEntity.dischargeInd) {
                        charge.directInd = batchUpdateEntity.dischargeInd;
                    }
                }
                if (batchUpdateEntity.seclegSpecialIndActive) {
                    if (batchUpdateEntity.seclegSpecialInd) {
                        charge.otherSpecialInd = batchUpdateEntity.seclegSpecialInd;
                    }
                    if (batchUpdateEntity.seclegDischargeInd) {
                        charge.otherDirectInd = batchUpdateEntity.seclegDischargeInd;
                    }
                    if (batchUpdateEntity.seclegOperatingTml) {
                        charge.otherOperatingTml = batchUpdateEntity.seclegOperatingTml;
                    }
                }
                if (batchUpdateEntity.permitNoActive) {
                    if (batchUpdateEntity.permitNo) {
                        charge.permitNo = batchUpdateEntity.permitNo;
                    }
                }
                if (batchUpdateEntity.rfInfoActive) {
                    if (batchUpdateEntity.noOfLift) {
                        charge.chargeQty = batchUpdateEntity.noOfLift;
                    }
                    if (batchUpdateEntity.createDatetime) {
                        charge.createDatetime = batchUpdateEntity.createDatetime;
                    }
                    if (batchUpdateEntity.reqPickupDatetime) {
                        charge.requestDateTime = batchUpdateEntity.reqPickupDatetime;
                    }
                    if (batchUpdateEntity.plugOnDatetime) {
                        charge.ptPlugOnDatetime = batchUpdateEntity.plugOnDatetime;
                    }
                    if (batchUpdateEntity.plugOffDatetime) {
                        charge.ptPlugOffDatetime = batchUpdateEntity.plugOffDatetime;
                    }
                    if (batchUpdateEntity.consStartDatetime) {
                        charge.pcPlugOnDatetime = batchUpdateEntity.consStartDatetime;
                    }
                    if (batchUpdateEntity.consEndDatetime) {
                        charge.pcPlugOffDatetime = batchUpdateEntity.consEndDatetime;
                    }
                }
                if (batchUpdateEntity.emptyChargeQtyActive) {
                    if (batchUpdateEntity.empty20Qty) {
                        charge.qty20 = batchUpdateEntity.empty20Qty;
                    }
                    if (batchUpdateEntity.empty40Qty) {
                        charge.qty40 = batchUpdateEntity.empty40Qty;
                    }
                    if (batchUpdateEntity.empty20Qty) {
                        charge.qty45 = batchUpdateEntity.empty45Qty;
                    }
                    if (batchUpdateEntity.empty20Qty) {
                        charge.qty48 = batchUpdateEntity.empty48Qty;
                    }
                    if (batchUpdateEntity.empty20Qty) {
                        charge.qty53 = batchUpdateEntity.empty53Qty;
                    }
                }
                if(batchUpdateEntity.bundleMasterNoActive || batchUpdateEntity.unBundleMasterNoActive){
                    charge.bundleNumber = null;
                    if (batchUpdateEntity.bundleMasterNumber) {
                        charge.bundleNumber = batchUpdateEntity.bundleMasterNumber;
                    }
                    charge.bundleVisitId = null;
                    if (batchUpdateEntity.bundleMasterVisit) {
                        charge.bundleVisitId = batchUpdateEntity.bundleMasterVisit;
                    }

                    charge.bundleNumberUpdatedInd = "Y";
                }
                if(batchUpdateEntity.tierFspActive){
                    if(batchUpdateEntity.firstTierFsp){
                        charge.firstTierFsp = batchUpdateEntity.firstTierFsp;
                    }
                    if(batchUpdateEntity.secondTierFsp){
                        charge.secondTierFsp = batchUpdateEntity.secondTierFsp;
                    }
                }
                if(batchUpdateEntity.voltageActive){
                    if(batchUpdateEntity.voltage){
                        charge.voltage = batchUpdateEntity.voltage;
                    }
                }
                if(batchUpdateEntity.offStdMeasurementModeActive){
                    if(batchUpdateEntity.offStdMeasurementMode){
                        charge.offStdMeasurementMode = batchUpdateEntity.offStdMeasurementMode;
                    }
                }
                if(batchUpdateEntity.marshallingTypeActive){
                    if(batchUpdateEntity.marshallingType){
                        charge.marshallingType = batchUpdateEntity.marshallingType;
                    }
                }
                if(batchUpdateEntity.specialHandlingCodeActive){
                    if(batchUpdateEntity.specialHandlingCode){
                        charge.specialHandlingCode = batchUpdateEntity.specialHandlingCode;
                    }
                }
                if(batchUpdateEntity.vesselInfoActive){
                    if(batchUpdateEntity.voyOperatingTml){
                        charge.voyageOperatingTml = batchUpdateEntity.voyOperatingTml;
                    }
                    if(batchUpdateEntity.voyServiceCode){
                        charge.voyageServiceCode = batchUpdateEntity.voyServiceCode;
                    }
                    if(batchUpdateEntity.firstDischargeDatetime){
                        charge.firstDischargeDateTime = batchUpdateEntity.firstDischargeDatetime;
                    }
                    if(batchUpdateEntity.lastDischargeDatetime){
                        charge.lastDischargeDateTime = batchUpdateEntity.lastDischargeDatetime;
                    }
                    if(batchUpdateEntity.firstLoadingDatetime){
                        charge.firstLoadingDateTime = batchUpdateEntity.firstLoadingDatetime;
                    }
                    if(batchUpdateEntity.lastLoadingDatetime){
                        charge.lastLoadingDateTime = batchUpdateEntity.lastLoadingDatetime;
                    }
                }
                if(batchUpdateEntity.tsVesselInfoActive){
                    if(batchUpdateEntity.otherArrDatetime){
                        charge.otherEtb = batchUpdateEntity.otherArrDatetime;
                    }
                    if(batchUpdateEntity.otherDepDatetime){
                        charge.otherEtd = batchUpdateEntity.otherDepDatetime;
                    }
                    if(batchUpdateEntity.otherDischargeDatetime){
                        //charge.other = batchUpdateEntity.otherDischargeDatetime;
                    }
                    if(batchUpdateEntity.otherFirstDischargeDatetime){
                        charge.otherFirstDischargeDatetime = batchUpdateEntity.otherFirstDischargeDatetime;
                    }
                    if(batchUpdateEntity.otherLastDischargeDatetime){
                        charge.otherLastDischargeDatetime = batchUpdateEntity.otherLastDischargeDatetime;
                    }
                }

                return {...currCharge, ...charge};
            })

            const batchUpdate = keepBatchUpdatePanel ? {...batchUpdateEntity} : EMPTY_CHARGE_BATCH_UPDATE_ENTITY;
            return await chargeDetailRepo.batchUpdate(chargeList).then((data) => {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"batchUpdteFail": data.toString()}
                        };
                    });
                    return {"batchUpdteFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"batchUpdateSuccess": `${batchUpdateEntity.bulkEditAction} successful`},
                            isShowBatchUpdatePanel: keepBatchUpdatePanel,
                            isAssignOneOffRate:false,
                            isAccTml: false,
                            isAdjustment: false,
                            isShowCntrInfoPanel: false,
                            isShowUpdateVesselVoyageMasterSearchPanel: false,
                            isShowUpdateVesselVoyageMasterTablePanel: false,
                            isShowAssignSeqNoPanel: false,
                            isShowRightEditPanel:false,
                            selectedChargeDetails:[],
                            chargeBatchUpdate: {...batchUpdate}
                        }
                    })
                    return {"batchUpdateSuccess": `${batchUpdateEntity.bulkEditAction} successful`};
                }
            }).catch(error => {
                return {"batchUpdteFail": error.message};
            });
        }
    }

    const onValidationBeforeBatchUpdateSave = async(batchUpdateEntity:ChargeBatchUpdateEntity) => {
        let result:boolean | null | undefined = undefined;
        if(batchUpdateEntity.oneOffRateActive) {  
            result = await Validation(updateOneOffRateValidationSchema).ValidateFormOnly(
                batchUpdateEntity
            ).then(res => {
                if (res) {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            chargeBatchUpdate: {
                                ...prevState.chargeBatchUpdate,
                                allFormState: res
                            }
                        }
                    })
                    return false;
                } else {
                    return true;
                }
            }).catch(error => {
                return false;
            });
        } else if (batchUpdateEntity.accResumeTerminalActive) {
            result = await Validation(updateAccountTerminalValidationSchema).ValidateFormOnly(
                batchUpdateEntity
            ).then(res => {
                if (res) {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            chargeBatchUpdate: {
                                ...prevState.chargeBatchUpdate,
                                allFormState: res
                            }
                        }
                    })
                    return false;
                } else {
                    return true;
                }
            }).catch(error => {
                return false;
            });
        } else if (batchUpdateEntity.adjustmentActive) {
            result = await Validation(updateAdjustmentValidationSchema).ValidateFormOnly(
                batchUpdateEntity
            ).then(res => {
                if (res) {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            chargeBatchUpdate: {
                                ...prevState.chargeBatchUpdate,
                                allFormState: res
                            }
                        }
                    })
                    return false;
                } else {
                    return true;
                }
            }).catch(error => {
                return false;
            });
        } else if (batchUpdateEntity.bundleMasterNoActive) {
            result = await Validation(updateBundleMasterValidationSchema).ValidateFormOnly(
                batchUpdateEntity
            ).then(res => {
                if (res) {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            chargeBatchUpdate: {
                                ...prevState.chargeBatchUpdate,
                                allFormState: res
                            }
                        }
                    })
                    return false;
                } else {
                    return true;
                }
            }).catch(error => {
                return false;
            });
        } else {
            result = true;
        }

        return result;
    }

    const onChargeDetailSeqUpdateSave = async(searchSeqCriteria:ChargeDetailSeqCriteria) => {        
        return await chargeDetailSeqCriteriaRepo.batchUpdate(searchSeqCriteria).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                chargeDetailDispatch(prevState => {
                    return {...prevState,
                        allFormState: {"assignSeqFail": data.toString()}
                    };
                });
                return {"assignSeqFail": data.toString()};
            } else {
                chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"assignSeqSuccess": `${MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace("{operationDone}", ChargeDetailConstant.Charge.ASSIGN_SEQ_NO)}`},
                    isShowBatchUpdatePanel: false,
                    isAssignOneOffRate:false,
                    isAccTml: false,
                    isAdjustment: false,
                    isShowCntrInfoPanel: false,
                    isShowUpdateVesselVoyageMasterSearchPanel: false,
                    isShowUpdateVesselVoyageMasterTablePanel: false,
                    isShowAssignSeqNoPanel: false,
                    isShowRightEditPanel:false,
                    selectedChargeDetails:[],
                    chargeBatchUpdate:EMPTY_CHARGE_BATCH_UPDATE_ENTITY,
                    ChargeDetailSeqCriteria: EMPTY_CHARGE_DETAIL_SEQ_CRITERIA_ENTITY
                }
                });
                return {"assignSeqSuccess": `${MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace("{operationDone}", ChargeDetailConstant.Charge.ASSIGN_SEQ_NO)}`};
            }
        }).catch(error => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    selectedChargeDetails:[],
                    allFormState: {"assignSeqFail": `${_.replace(MessageConstant.common.OPERATION_DONE_Failure, ChargeDetailConstant.Charge.ASSIGN_SEQ_NO)}`}
                }
            });
            return {"assignSeqFail": error.message};
        })
    }
    

    const handleRowDoubleClick = async(chargeDetail: ChargeDetailEntity) => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                chargeDetailState: {
                    ...prevState.chargeDetailState,
                    isEditable: true,
                    isRead: true,
                    currentSelectedData: chargeDetail
                }
            }
        })
    }

    const handleVslVoyRowDoubleClick = async(vesselVoyage: VesselVoyageMasterEntity) => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                vslVoyState: {
                    ...prevState.vslVoyState,
                    isEditable: true,
                    isRead: true,
                    currentSelectedVslVoyData: vesselVoyage
                }
            }
        })
    }

    const handleDeleteCharge = async(chargeDetails: ChargeDetailEntity[]) => {
        if (!chargeDetails) return;        
        const validateMsg = validateChargeDelete(chargeDetails);
        if (validateMsg) {
            return {"deleteChargeFail": validateMsg};
        }

        return await chargeDetailRepo.deleteCharge(chargeDetails).then((data) => {
            if (data) {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"deleteChargeFail": data.toString()}
                        };
                    });
                    return {"deleteChargeFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            selectedChargeDetails:[],                     
                        }
                    });
                }
            }            
        }).catch((error) => {
            throw new Error(error.messge);
        })
    }

    const handleAdjustment = async(chargeDtlList:ChargeDetailEntity[], keepBatchUpdatePanel:boolean=false) => {
        return await chargeDetailRepo.adjustment(chargeDtlList).then((data) => {
            if (data) {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"adjustmentChargeFail": data.toString()}
                        };
                    });
                    return {"adjustmentChargeFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"adjustmentChargeSuccess":"successful"},
                            isShowBatchUpdatePanel: keepBatchUpdatePanel,
                            isAssignOneOffRate:false,
                            isAccTml: false,
                            isAdjustment: keepBatchUpdatePanel,
                            isShowCntrInfoPanel: false,
                            isShowUpdateVesselVoyageMasterSearchPanel: false,
                            isShowUpdateVesselVoyageMasterTablePanel: false,
                            isShowAssignSeqNoPanel: false,
                            isShowRightEditPanel:true,
                            chargeBatchUpdate:EMPTY_CHARGE_BATCH_UPDATE_ENTITY                      
                        }
                    })
                    return  {"adjustmentChargeSuccess":"successful"};
                }            
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"adjustmentChargeFail": error.message}                        
                }
            });
            return {"adjustmentChargeFail": error.message};
        })
    }

    const handleApply = async(chargeDtlList:ChargeDetailEntity[]) => {
        return await chargeDetailRepo.apply(chargeDtlList).then((data) => {
            if (data) {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"applyChargeFail": data.toString()}
                        };
                    });
                    return {"applyChargeFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            selectedChargeDetails:[],
                            allFormState: {"applyChargeSuccess":""}                        
                        }
                    });
                    return {"applyChargeSuccess": "successful"};
                }
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"applyChargeFail": error.message}                        
                }
            });
            
            return {"applyChargeFail": error.message};
        })
    }

    const handleApplyAll = async(chargeHeader:ChargeHeaderEntity) => {
        return await chargeDetailRepo.applyAll(chargeHeader).then((data) => {
            if (data) {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"applyAllChargeFail": data.toString()}
                        };
                    });
                    return {"applyAllChargeFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            selectedChargeDetails:[],
                            allFormState: {"applyAllChargeSuccess":""}                        
                        }
                    });
                    return {"applyAllChargeSuccess":"successful"} 
                }
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"applyAllChargeFail": error.message}                        
                }
            });
            return {"applyAllChargeFail": error.message};
        })
    }

    const handleConfirm = async(chargeDtlList:ChargeDetailEntity[]) => {
        return await chargeDetailRepo.confirm(chargeDtlList).then((data) => {
            if (data) {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"confirmChargeFail": data.toString()}
                        };
                    });
                    return {"confirmChargeFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        selectedChargeDetails:[],
                        allFormState: {"confirmChargeSuccess":""}                        
                    };
                   });
                   return {"confirmChargeSuccess": "successful"};
                }
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"confirmChargeFail": error.message}                        
                }
            });
            return {"confirmChargeFail": error.message};
        })
    }

    const handleConfirmAll = async(chargeHeader:ChargeHeaderEntity) => {
        return await chargeDetailRepo.confirmAll(chargeHeader).then((data) => {
            if (data) {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"confirmAllChargeFail": data.toString()}
                        };
                    });
                    return {"confirmAllChargeFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        selectedChargeDetails:[],
                        allFormState: {"confirmAllChargeSuccess":""}                        
                    }
                    });
                    return {"confirmAllChargeSuccess": "successful"};
                }
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"confirmAllChargeFail": error.message}                        
                }
            });
            return {"confirmAllChargeFail": error.message};
        })
    }

    const handleUnconfirm = async(chargeDtlList:ChargeDetailEntity[]) => {
        return await chargeDetailRepo.unConfirm(chargeDtlList).then((data) => {
            if (data) {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"unconfirmChargeFail": data.toString()}
                        };
                    });
                    return {"unconfirmChargeFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        selectedChargeDetails:[],
                        allFormState: {"unconfirmChargeSuccess":"successful"}                        
                    }
                    });
                    return {"unconfirmChargeSuccess":"successful"}  ;
                }
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"unconfirmChargeFail": error.message}                        
                }
            });
            return {"unconfirmChargeFail": error.message};
        })
    }

    const handleUnconfirmAll = async(chargeHeader:ChargeHeaderEntity) => {
        return await chargeDetailRepo.unConfirmAll(chargeHeader).then((data) => {
            if (data) {
                if (data && data.toString().startsWith("Error:")) {
                    chargeDetailDispatch(prevState => {
                        return {...prevState,
                            allFormState: {"unconfirmAllChargeFail": data.toString()}
                        };
                    });
                    return {"unconfirmAllChargeFail": data.toString()};
                } else {
                    chargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        selectedChargeDetails:[],
                        allFormState: {"unconfirmAllChargeSuccess":""}                        
                    }
                    });
                    return {"unconfirmAllChargeSuccess":"successful"};
                }
            }
        }).catch((error) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"unconfirmAllChargeFail": error.message}                        
                }
            });
            return {"unconfirmAllChargeFail": error.message};
        })
    }

    const updateSelectedCharges = (rows:any[]) => {        
        chargeDetailDispatch(prevState => {
            const buttonState = updateButtonState(rows);
            const initialMenuItems:InitialMenuItemsState = setChargeBulkMenuMark(rows)??EMPTY_INITIAL_MENU_ITEMS_STATE;
            return {
                ...prevState,
                selectedChargeDetails: rows,
                ...buttonState,
                chargeBatchUpdate: {
                    ...prevState.chargeBatchUpdate,
                    ...initialMenuItems
                }
            }
        })
    }

    const updateSelectedVslVoys = (rows:any[]) => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                selectedVesselVoyageMasters: rows                       
            }
        })
    }

    const onDropdownChange = (e: any, fieldName: string, obj:any, objName:string) => {
        obj = {
            ...obj, 
            [fieldName]: e?.value ?? (_.isEmpty(e) ? 
             (fieldName === "subChgTypeList" ? [] : '') : e)};
        chargeDetailDispatch(prevState => ({
            ...prevState,
            [objName]: obj
        }))
    }    

    const onSeqDropdownChange = (e: any, fieldName: string, obj:any, objName:string) => {
        obj = {
            ...obj, 
            [fieldName]: e?.value ?? (_.isEmpty(e) ? 
             (fieldName === "subChgTypeList" ? [] : '') : e)};
        chargeDetailDispatch(prevState => ({
            ...prevState,
            [objName]: obj
        }))
        updateSeqCriteria(obj);
    }    

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string, obj:any, objName:string, toUpperCase:boolean=false) => {
        const val = toUpperCase ? e?.target.value.toUpperCase() : e?.target.value;
        if (fieldName === "bundleMasterNumber") {
            obj = {
                ...obj, 
                bundleMasterVisit: '',
                [fieldName]: val,                            
            };
            
            chargeDetailDispatch(prevState => ({
                ...prevState,
                [objName]: obj,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    visitNumberOptions: []
                }
            }))
        } else {
            obj = {
                ...obj, 
                [fieldName]: val
            };
                
            chargeDetailDispatch(prevState => ({
                ...prevState,
                [objName]: obj
            }))
        }
    };

    const onDatePickerChange = (fieldName: string, val:any) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            chargeBatchUpdate: {
                ...prevState.chargeBatchUpdate,
                [fieldName]: val,
            }
        }))
    }

    const onVslVoyDatePickerChange = (inputData: Date, fieldName: { date1: string}) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            searchVslVoyCriteria: {
                ...prevState.searchVslVoyCriteria,
                [fieldName.date1]: inputData??null,
            }
        }))
    }

    const onVslVoyDateFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
      if (_.isArray(val)) {
        val = _.uniq(val?.map((item: any) => item.value || item.key));
      }     
      return chargeDetailDispatch(prevState => {
        return {
            ...prevState,
            searchVslVoyCriteria: {
                ...prevState.searchVslVoyCriteria,
                [fieldKey]: val
            }            
        }
      });
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    // const onMultipleVslVoyDropdownChange = (e: any, fieldName: string) => {
    //     chargeDetailDispatch(prevState => {
    //         return {
    //             ...prevState,
    //             searchVslVoyCriteria: {
    //                 ...prevState.searchVslVoyCriteria,
    //                 [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
    //                 // [fieldName]: e?.value ?? (_.isEmpty(e) ? '' : e),
    //             }
    //         }
    //     });
    // };

    const onSearchDropdownChange = (e: any, fieldName: string) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    (fieldName === "subChargeTypes" ? [] : '') : e),
            }
        }))
    }

    const onSearchSeqDropdownChange = (e: any, fieldName: string) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            searchSeqCriteria: {
                ...prevState.searchSeqCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                (fieldName === "subChargeTypes" ? [] : '') : e),
            }
        }))
    }

    const onSearchVslVoyDropdownChange = (e: any, fieldName: string) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            searchVslVoyCriteria: {
                ...prevState.searchVslVoyCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    (fieldName === "subChargeTypes" ? [] : '') : e),
            }
        }))
    }

    const onSearchVslVoyInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            searchVslVoyCriteria: {
                ...prevState.searchVslVoyCriteria,
                [fieldName]: fieldName === 'uniqueCntrNo' ? e.target.value.toString().toUpperCase() : e.target.value,
            }
        }))
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string, toUpperCase:boolean=false) => {
        const val = toUpperCase ? e.target.value.toString().toUpperCase() : e.target.value
        chargeDetailDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === 'cntrNos' ? 
                    (val&&val.toString().includes(",") ? val.toString().split(",") : [val.toString()]) : 
                    e.target.value.toString(),
            }
        }))
    };
    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        chargeDetailDispatch(prevState => ({
            ...prevState,
            searchVslVoyCriteria: {
                ...prevState.searchVslVoyCriteria,
                [fieldName.co]: inputData?.co?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.vsl]: inputData?.vsl?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.voy]: inputData?.voy?.toUpperCase().replace(/\s+/g, ''),
            }
        }))
    }
    const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string, toUpperCase:boolean=false) => {
        const val = toUpperCase || fieldName === 'uniqueCntrNo' ? e.target.value.toString().toUpperCase() : e.target.value
        chargeDetailDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: val,
            }
        }))
    };

    const onAccordionPanelChange = (e: boolean, batchUpdateEty: ChargeBatchUpdateEntity, fieldKey:string) => {
        batchUpdateEty = {...batchUpdateEty, [fieldKey]: e};
        chargeDetailDispatch(prevState => ({
            ...prevState,
            chargeBatchUpdate: batchUpdateEty
        }))
    };

    const assignCommonAccount = async(ids: string[]) => {
        return await chargeDetailRepo.assignCommonAccount(ids).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                chargeDetailDispatch(prevState => {
                    return {...prevState,
                        allFormState: {"assignCommonAccountFail": data.toString()}
                    };
                });
                return {"assignCommonAccountFail": data.toString()};
            } else {
                chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    selectedChargeDetails:[],
                    allFormState: {"assignCommonAccountSuccess":""}    
                }
                });
                return {"assignCommonAccountSuccess": "successful"};
            }            
        }).catch((error) => {
            return {"assignCommonAccountFail": error.message};
        })
    }
    const reverseCommonAccount = async(commonAcSeqs: Object[]) => {
        return await chargeDetailRepo.reverseCommonAccount(commonAcSeqs).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                chargeDetailDispatch(prevState => {
                    return {...prevState,
                        allFormState: {"reverseCommonAccountFail": data.toString()}
                    };
                });
                return {"reverseCommonAccountFail": data.toString()};
            } else {
                chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    selectedChargeDetails:[],
                    allFormState: {"reverseCommonAccountSuccess":""}    
                }
                })
                return {"reverseCommonAccountSuccess": "successful"};
            }
        }).catch((error) => {
            return {"reverseCommonAccountFail": error.message};
        })
    }
    const redirectCommonAccount = async(ids: string[]) => {
        return await chargeDetailRepo.redirectCommonAccount(ids).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                chargeDetailDispatch(prevState => {
                    return {...prevState,
                        allFormState: {"redirectCommonAccountFail": data.toString()}
                    };
                });
                return {"redirectCommonAccountFail": data.toString()};
            } else {
                chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    selectedChargeDetails:[],
                    allFormState: {"redirectCommonAccountSuccess":""}    
                }
                })
                return {"redirectCommonAccountSuccess": "successful"};
            }
        }).catch((error) => {
            return {"redirectCommonAccountFail": error.message};
        })
    }

    const updateCommonAccountClick = async() => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: true,
                isUptAbsolutelyCommonAc:true,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true,
                chargeBatchUpdate: {
                    ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY, 
                    uptAbsolutelyCommonAcEnable: true,
                    accResumeTerminalEnable: false,
                    oneOffRateEnable: false,
                    copyCommonAcEnable: false,
                    adjustmentEnable: false,
                }
            }
        })
    }
    const copyCommonAccountClick = async() => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowBatchUpdatePanel: true,
                isCopyCommonAc:true,
                isShowCntrInfoPanel: false,
                isShowUpdateVesselVoyageMasterSearchPanel: false,
                isShowUpdateVesselVoyageMasterTablePanel: false,
                isShowAssignSeqNoPanel: false,
                isShowRightEditPanel: false,
                isBatchUpdate: true,
                chargeBatchUpdate: {
                    ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY, 
                    //accResumeTerminalEnable: true,
                    //holdEnable: true,
                    //oneOffRateEnable: true,
                    //uptAbsolutelyCommonAcEnable: true,
                    copyCommonAcEnable: true,
                    oneOffRateEnable: false,
                    uptAbsolutelyCommonAcEnable: false,
                    adjustmentEnable: false,
                }
            }
        })
    }

    const initSeqCriteria = async(searchSeqCriteria: ChargeDetailSeqCriteria, selectedChargeDetails: ChargeDetailEntity[]) => {
        searchSeqCriteria.chargeList = selectedChargeDetails;
        await chargeDetailSeqCriteriaRepo.initForm(searchSeqCriteria).then((data) => {
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    searchSeqCriteria : data
                }
            })
            loadChargeDetailSeqList(data);
        }).catch((error) => {
            return [];
        })
    }

    const updateSeqCriteria = async(searchSeqCriteria: ChargeDetailSeqCriteria) => {
        await chargeDetailSeqCriteriaRepo.updateForm(searchSeqCriteria).then((data) => {
            
            chargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    searchSeqCriteria : data
                }
            })
            loadChargeDetailSeqList(data);
        }).catch((error) => {
            return [];
        })
    }
    const loadChargeDetailSeqList = async(searchSeqCriteria: ChargeDetailSeqCriteria) => {
        let seqDropdownOptions = searchSeqCriteria.seqList?.map((item) => ({
            dropdownLabel: item,
            tagLabel: item,
            value: item,
        })) ?? []
        seqDropdownOptions = _.orderBy(seqDropdownOptions, "dropdownLabel");
        chargeDetailDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                seqDropdownOptions: seqDropdownOptions,
            }
        }))
    }
    //batch update


    //data handle
    const handleLegend = () => {
        chargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowLegend: !prevState.isShowLegend,                      
            }
        })
    }

    const validateMasterBundle = async(selectedChargeDetails: ChargeDetailEntity[], inputBundleNumber:string,
        inputBundleVisit:string
    ) => {
        const request:ValidateMasterBundleRequest = {
            selectedChargeDetails: selectedChargeDetails,
            masterBundleNo: inputBundleNumber,
            masterBunderVisit: inputBundleVisit,
        }
        
        return await chargeDetailRepo.validateMasterBundle(request).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                chargeDetailDispatch(prevState => {
                    return {...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            visitNumberOptions: []
                        },
                        allFormState: {"validateMasterBundleFail": data.toString()}
                    };
                });
                return {"validateMasterBundleFail": data.toString()};
            } else {                
                return {"validateMasterBundleSuccess": "successful"};
            }
        }).catch((error) => {
            return {"validateMasterBundleFail": error.message};
        })
    }

    const searchMasterBundleVisit = async(selectedChargeDetails: ChargeDetailEntity[], inputBundleNumber:string) => {
        const request:ValidateMasterBundleRequest = {
            selectedChargeDetails: selectedChargeDetails,
            masterBundleNo: inputBundleNumber,
            masterBunderVisit: "",
        }
        
        return await chargeDetailRepo.searchMasterBundleVisit(request).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                chargeDetailDispatch(prevState => {
                    return {...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            visitNumberOptions: []
                        },
                        allFormState: {"searchMasterBundleFail": data.toString()}
                    };
                });
                return {"searchMasterBundleFail": data.toString()};
            } else {                
                const visitNumberOptions = data?.map((visit) => ({
                    dropdownLabel: visit,
                    tagLabel: visit,
                    value: visit?.substring(0, (visit?.indexOf("-") <= 0 ? 0 :  visit?.indexOf("-") - 1)),
                })) ?? [];
                _.orderBy(visitNumberOptions, "dropdownLabel");
                chargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            visitNumberOptions: visitNumberOptions
                        },
                        chargeBatchUpdate: {
                            ...prevState.chargeBatchUpdate,
                            bundleMasterVisit: visitNumberOptions[0]?.value,
                        },
                        allFormState: {"searchMasterBundleSuccess":""}    
                    }
                })
                return {"searchMasterBundleSuccess": "successful"};
            }
        }).catch((error) => {
            return {"searchMasterBundleFail": error.message};
        })
    }
    
    return {
        onCloseClick: onCloseClick,
        onBatchUpdateCloseClick: onBatchUpdateCloseClick,
        onCntrInfoCloseClick: onCntrInfoCloseClick,
        onAssignSeqNoCloseClick: onAssignSeqNoCloseClick,
        onVslVoyCloseClick: onVslVoyCloseClick,
        onBulkEditClick: onBulkEditClick,
        onUpdateCntrInfoClick: onUpdateCntrInfoClick,
        onAssignSeqNoClick: onAssignSeqNoClick,
        onUpdateVslVoySearchClick: onUpdateVslVoySearchClick,
        onUpdateVslVoyTableClick: onUpdateVslVoyTableClick,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onVslVoySearchCriteriaResetClick: onVslVoySearchCriteriaResetClick,       
        onAdjustmentClick: onAdjustmentClick,
        onVslVoyApplyClick: onVslVoyApplyClick,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onBatchUpdateSave: onBatchUpdateSave,
        onChargeDetailSeqUpdateSave: onChargeDetailSeqUpdateSave,
        getChargeDetails: getChargeDetails,
        getVesselVoyageMaster: getVesselVoyageMaster,
        onDropdownChange: onDropdownChange,
        onSeqDropdownChange: onSeqDropdownChange,
        loadDropdownOption: loadDropdownOption,
        handleRowDoubleClick: handleRowDoubleClick,
        handleVslVoyRowDoubleClick: handleVslVoyRowDoubleClick,
        onSearchClick: onSearchClick,
        onSummryClick: onSummryClick,
        onCloseSummary: onCloseSummary,
        onInputTextChange: onInputTextChange,
        onVslVoyDatePickerChange: onVslVoyDatePickerChange,
        onVslVoyDateFieldChange: onVslVoyDateFieldChange,
        handleDeleteCharge: handleDeleteCharge,
        handleAdjustment: handleAdjustment,
        handleApply: handleApply,
        handleApplyAll: handleApplyAll,
        handleConfirm: handleConfirm,
        handleConfirmAll: handleConfirmAll,
        handleUnconfirm: handleUnconfirm,
        handleUnconfirmAll: handleUnconfirmAll,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onSearchDropdownChange: onSearchDropdownChange,
        onSearchVslVoyDropdownChange: onSearchVslVoyDropdownChange,
        onSearchSeqDropdownChange: onSearchSeqDropdownChange,
        onSearchVslVoyInputTextChange: onSearchVslVoyInputTextChange,
        onTextAreaChange: onTextAreaChange,
        onCoVslVoyChange: onCoVslVoyChange,
        onSearchInputTextChange: onSearchInputTextChange,
        updateSelectedCharges: updateSelectedCharges,
        updateSelectedVslVoys: updateSelectedVslVoys,
        onAccordionPanelChange: onAccordionPanelChange,
        onOneOffRateClick: onOneOffRateClick,
        onAccTmlClick: onAccTmlClick,
        assignCommonAccount: assignCommonAccount,
        reverseCommonAccount: reverseCommonAccount,
        redirectCommonAccount: redirectCommonAccount,
        updateCommonAccountClick: updateCommonAccountClick,
        copyCommonAccountClick: copyCommonAccountClick,
        initSeqCriteria: initSeqCriteria,
        updateSeqCriteria: updateSeqCriteria,
        loadChargeDetailSeqList: loadChargeDetailSeqList,
        handleLegend: handleLegend,
        onValidationBeforeBatchUpdateSave: onValidationBeforeBatchUpdateSave,
        onDatePickerChange: onDatePickerChange,
        onUpdateCntrStatusClick: onUpdateCntrStatusClick,
        getChargeDetailsForDeliverySummary: getChargeDetailsForDeliverySummary,
        getChargeDetailSummary: getChargeDetailSummary,
        getChargeDetailOutstanding: getChargeDetailOutstanding,
        validateMasterBundle: validateMasterBundle,
        searchMasterBundleVisit: searchMasterBundleVisit,
        onUnbundleClick: onUnbundleClick
    }
}